import { styled } from 'solid-styled-components';
import theme from '../../style/theme';
import { Button } from '../button/button';
import { Heading } from '../heading/heading';
import { Text } from '../text/text';
import { Modal } from '../modal/modal';
import { useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';

const StyledModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 0 6rem;

    ${theme.mediaQueries.tabletMax} {
        padding: 0;
    }
`;

const StyledButtonRow = styled.div`
    display: flex;
    justify-content: start;
    gap: 3rem;

    ${theme.mediaQueries.tabletMax} {
        flex-direction: column;
        gap: 0;

        button {
            width: 100%;
        }
    }
`;

type CookieModalProps = {
    close: () => void;
    setFirstTabbableElement: (el?: any) => void;
    setLastTabbableElement: (el?: any) => void;
    closeButtonRef?: any;
};

export const CookieModal = (props: CookieModalProps) => {
    const { localize } = useContext(AppContext);

    const acceptCookie = () => {
        props.close();
        window.Cookiebot.renew(); // Trigger Cookiebot's consent pop-up
    };
    
    return (
        <Modal
            visible={true}
            onClose={() => props.close()}
            size='normal'
            darkenBackground={true}
            closeButtonRef={(el: HTMLElement) => props.setFirstTabbableElement(el)}
        >
            <StyledModal>
                <Heading tag="h3">{localize('external-media-cookie-warning-header', 'Cookie information')}</Heading>
                <Text fontSize="normal" color="gray">{localize('external-media-cookie-warning-text', 'This media is from an external source that requires marketing cookies. To play the media, please enable marketing cookies in the upcoming popup.')}</Text>
                <StyledButtonRow>
                    <Button variant="primary" label={localize('continue', 'Continue')} onClick={acceptCookie} />
                    <Button variant="secondary" label={localize('cancel', 'Cancel')} onClick={() => props.close()} element={(el: HTMLElement) => props.setLastTabbableElement(el)} />
                </StyledButtonRow>
            </StyledModal>
        </Modal>
    );
};

