import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular } from '../../../style/theme';

const { spacing } = theme;

type StyledProductVideoProps = {
    ableToPlay: boolean;
    videoCookiesAccepted: boolean;
};

type StyledPostCardProps = {
    inGrouping?: boolean;
    noBorder?: boolean;
};

export const StyledVideoCardWrapper = styled.div<{ large?: boolean }>`
    height: ${p => p.large ? '100%' : 'auto'};
    width: 100%;
`;

export const StyledVideoPostCard = styled.div<StyledPostCardProps>`
    border-radius: 1.43rem;
    height: fit-content;
    min-height: 25rem;
    width: 100%;
    background-color: white;
    list-style: none;
    position: relative;
    text-decoration: none;
    position: relative;
    ${(p) =>
        p.noBorder
            ? ''
            : `
        border: 1px solid ${theme.palette.lightGray};
    `}
    overflow: hidden;
    ${(p) =>
        !p.inGrouping
            ? `
                margin-block: 2rem;
            `
            : ''}
    transition: transform 0.2s, box-shadow 0.2s;
    &:hover {
        box-shadow: 0.2rem 0.2rem 0.86rem 0px rgba(0,0,0,0.09);
        transform: scale(0.97);
    }

`;

export const StyledProductVideo = styled.button<StyledProductVideoProps>`
    display: block;
    cursor: pointer;
    position: relative;
    width: 100%;

    padding-bottom: 56.25%;
    height: 0;

    iframe,
    .styledImage {
        ${(p) =>
        !p.ableToPlay
            ? `
            pointer-events: none;
        `
            : ''}
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
`;

export const StyledImage = styled.img`
    background-color: ${theme.palette.lightGray};
    width: 100%;
    object-fit: cover;
    object-position: center;
`;

export const StyledCardContent = styled.div`
    padding: ${spacing.xs} ${spacing.s} ${spacing.xs} ${spacing.s};
    border-top: 1px solid ${theme.palette.lightGray};

    div {
        justify-content: flex-start;
    }
`;

export const StyledDate = styled.div`
    font-family: ${fontFamilyAtosRegular};
    font-size: 1.29rem;
    color: ${theme.palette.darkGray};
    text-decoration: none;
    margin-bottom: ${spacing.xxs};
`;

export const StyledBottomRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${spacing.xs};
`;

export const StyledCardTitle = styled.div<{insideSlider?: boolean;}>`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 1.67rem;
    color: ${theme.palette.darkGray};
    text-decoration: none;
    margin-bottom: ${spacing.xs};
    margin-right: 1rem;

    ${p => p.insideSlider ? `
        height: 5rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    ` 
        : ''}
`;

export const StyledOverlayContent = styled.div`
    background-color: white;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 1.43rem;
    transition: ease-in-out 0.3s all;
    z-index: 1;

    &:hover {
        opacity: 1;
    }
`;

export const StyledOverlayContentInner = styled.div`
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    bottom: 0;
    padding: ${spacing.s};
`;

export const StyledDescription = styled.div`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: ${theme.palette.darkGray};
    font-size: 1.29rem;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
`;

export const StyledOverlayBottomRow = styled.div`
    bottom: 1.64rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
`;

export const StyledReadMoreText = styled.div`
    color: ${theme.palette.red};
    font-size: 1.8rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    margin-right: 1rem;
`;
