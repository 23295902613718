// solid-js

import { For, Show } from 'solid-js';
import { Link } from '../../ui-components/link/link';
import { StyledBreadcrumbList, StyledBreadcrumbListItem, StyledBreadCrumbNav, StyledBreadcrumbSlash } from './breadcrumb.styles';
import { ErrorCatcher } from '../../tools/error-catcher';
import removeAmpersand from '../../tools/remove-ampersand';
import { removeTrailingSlash } from '../../tools/remove-trailing-slash';

type MetaData = {
    postTitle: string;
    shortTitle: string;
    permalink: string;
}

type BreadcrumbProps = {
    pages: MetaData[];
    url: string;
};

export const Breadcrumb = ({ pages, url }: BreadcrumbProps) => {
    const urlMap = url.split('/').filter(Boolean);

    const getLabel = (page: MetaData, index: number) => {
        if (page?.shortTitle || page?.postTitle) {
            return page.shortTitle || page.postTitle;
        }

        // Capitlize first letter and replace hyphens with spaces
        let title = urlMap[index].charAt(0).toUpperCase() + urlMap[index].slice(1);        
        title = title.replace(/-/g, ' ');

        return removeAmpersand(title);
    };

    const getUrl = (page: MetaData, index: number) => {
        const formedUrl = urlMap.slice(0, index + 1).join('/');
        return removeTrailingSlash(formedUrl);
    };

    return (
        <ErrorCatcher componentName="Breadcrumb">
            <StyledBreadCrumbNav aria-label="breadcrumb">
                <StyledBreadcrumbList>
                    <For each={pages}>{(page, index) => (
                        <StyledBreadcrumbListItem>
                            <Show when={index() !== 0}>
                                <StyledBreadcrumbSlash>
                                    /
                                </StyledBreadcrumbSlash>
                            </Show>
                            <Link
                                url={getUrl(page, index())}
                                label={getLabel(page, index())}
                                plainStyle={true}
                                size='small'
                            />
                        </StyledBreadcrumbListItem>
                    )}</For>
                </StyledBreadcrumbList>
            </StyledBreadCrumbNav>
        </ErrorCatcher>
    );
};

