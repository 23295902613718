/* eslint-disable indent */

import { styled } from 'solid-styled-components';
import { theme } from '../../style/theme';
import { StyledSidebarContainerLine } from '../../ui-components/layouts/sidebar.style';

const headerHeightValue = theme.header.desktop.height.fixed.value;
const headerHeightUnit = theme.header.desktop.height.fixed.unit;

const headerHcpHeightValue = theme.header.desktop.height.fixedHcp.value;
const headerHcpHeightUnit = theme.header.desktop.height.fixedHcp.unit;

const mobileHeaderHeightValue = theme.header.mobile.height.value;
const mobileHeaderHeightUnit = theme.header.mobile.height.unit;

const headerAtosCareHeightValue = theme.header.desktop.height.fixedAtosCare.value;
const headerAtosCareHeightUnit = theme.header.desktop.height.fixedAtosCare.unit;

type PageStyleProps = {
    hasHero: boolean;
    isAtosCareSite: boolean;
    isPdfBuilderPage?: boolean;
    adjustForHcpHeader: boolean;
};

export const PageStyled = styled.div<PageStyleProps>`
    container-type: inline-size;
    overflow: clip;

    ${(p) => {
        if (p.hasHero) {
            if (p.isAtosCareSite) {
                return `margin-top: ${-1 * headerAtosCareHeightValue}${headerAtosCareHeightUnit};`;
            }

            return p.adjustForHcpHeader
                ? `margin-top: ${-1 * headerHcpHeightValue}${headerHcpHeightUnit};`
                : `margin-top: ${-1 * headerHeightValue}${headerHeightUnit};`;
        } else {
            if (p.adjustForHcpHeader) {
                return `
                    ${theme.mediaQueries.desktopMin} {
                        margin-top: 9rem;
                    }
                    margin-top: 7rem;
                `;
            }

            return '';
        }
    }}

    ${theme.mediaQueries.tabletMax} {
        ${(p) => (p.hasHero && !p.isAtosCareSite ? `margin-top: ${-1 * mobileHeaderHeightValue}${mobileHeaderHeightUnit};` : '')}
    }

    @media print {
        display: none;
    }
`;

export const StyledSidebarContainerLineHCP = styled(StyledSidebarContainerLine)`
    padding-top: 3rem;
    ${theme.mediaQueries.tabletMax} {
        display: none;
    }
`;

export const StyledBreadcrumbWrapper = styled.div<{ hasHero: boolean }>`
    padding-top: ${p => p.hasHero ? '3.5rem' : '1.5rem'};
`;

export const StyledAddToMyContentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
