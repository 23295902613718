import { StyledPodcastPdf, StyledPodcastPdfDescriptionWrapper, StyledPodcastPdfImage, StyledQrCode } from './podcast-pdf.style';
import { Heading } from '../../../../../../ui-components/heading/heading';
import { useContext } from 'solid-js';
import { AppContext } from '../../../../../../app-context-provider/app-context-provider';
import { generateQRCode } from '../../generate-qr';
import { SpacerPdf } from '../spacer/spacer-pdf';
import { Text } from '../../../../../../ui-components/text/text';

type PodcastPdfProps = {
    podcastProvider: string;
    id: string;
    url: string;
};

export const PodcastPdf = (props: PodcastPdfProps) => {
    const { localize } = useContext(AppContext);

    return (
        <div>
            <SpacerPdf height={2.2} />
            <StyledPodcastPdf>
                <StyledPodcastPdfImage>
                    {/* <PlayAndCircle /> */}
                    <svg width="100px" height="100px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 14.4856 19.9937 16.7342 18.364 18.364C17.9734 18.7545 17.9734 19.3876 18.364 19.7782C18.7545 20.1687 19.3876 20.1687 19.7782 19.7782C21.7677 17.7887 23 15.0373 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 15.0373 2.23231 17.7887 4.22183 19.7782C4.61235 20.1687 5.24551 20.1687 5.63604 19.7782C6.02656 19.3876 6.02656 18.7545 5.63604 18.364C4.00626 16.7342 3 14.4856 3 12ZM7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12C17 13.381 16.4415 14.6296 15.5355 15.5355C15.145 15.9261 15.145 16.5592 15.5355 16.9497C15.9261 17.3403 16.5592 17.3403 16.9497 16.9497C18.2154 15.6841 19 13.9327 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 13.9327 5.7846 15.6841 7.05025 16.9497C7.44078 17.3403 8.07394 17.3403 8.46447 16.9497C8.85499 16.5592 8.85499 15.9261 8.46447 15.5355C7.55855 14.6296 7 13.381 7 12ZM14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12ZM9.67319 17.494C9.57955 16.1455 10.6482 15 12 15C13.3518 15 14.4205 16.1455 14.3268 17.494L14.0049 22.1295C13.9317 23.1829 13.0559 24 12 24C10.9441 24 10.0683 23.1829 9.9951 22.1295L9.67319 17.494Z" fill="#000000" />
                    </svg>
                </StyledPodcastPdfImage>
                <StyledPodcastPdfDescriptionWrapper>
                    <Heading
                        tag='h5'
                        variant='mediumPDF'
                        color={{ name: 'black', color: 'black'}}
                    >
                        {localize('play-podcast-episode-by-scanning-the-code', 'Play podcast episode by scanning the code')}
                    </Heading>

                    <div style={{ 'word-break': 'break-all' }}>
                        <Text fontSize='smaller' noBlockSpacing >
                            {props.url}
                        </Text>
                    </div>

                </StyledPodcastPdfDescriptionWrapper>
                <StyledQrCode innerHTML={generateQRCode(props.url)} />

            </StyledPodcastPdf>
            <SpacerPdf height={2.2} />
        </div>
    );
};

PodcastPdf.parseProps = (atts: any) => {
    let url = '';

    if (atts.podcastProvider === 'buzzsprout' && atts.id) {
        url = `https://www.buzzsprout.com/${atts.id}`;
    } else if (atts.podcastProvider === 'captivate' && atts.id) {
        url = `https://player.captivate.fm/episode/${atts.id}`;
    }

    return {
        podcastProvider: atts.podcastProvider,
        id: atts.id,
        url,
    };
};

