/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GraphQLClient } from 'graphql-request';
import { hydrate } from 'solid-js/web';
import { Router } from '@solidjs/router';

import { AppContextProvider, SyncStore } from '../isomorphic/app-context-provider/app-context-provider';
import { App } from '../isomorphic/app/app';
import { AppState } from '../isomorphic/types/app-state';
import { MetaProvider } from '@solidjs/meta';
import { createStore } from 'solid-js/store';

// Get the appstate from the SSR
const appState: AppState = JSON.parse(document.getElementById('_appstate')!.innerText);

const client = new GraphQLClient('/api');

const graphqlClient = (query: string, variables: any, headers?: any) => client.request(query, variables, headers);

const syncStore = createStore<SyncStore>(appState.syncStore);

hydrate(
    () => (
        // REMINDER: We have a boot-server as well where stuff has to be added
        <AppContextProvider
            imagesServiceUrl={appState.settings.imagesServiceUrl}
            syncStore={syncStore}
            client={graphqlClient}
            cache={appState.cache}
            supportedImageFormats={appState.settings.supportedImageFormats}
            viewport={appState.settings.viewport}
            mobile={appState.settings.mobile}
            productsEntityName={appState.settings.generalOptions?.productsEntityName}
            blogAndNewsEntityName={appState.settings.generalOptions?.blogAndNewsEntityName}
            eventsEntityName={appState.settings.generalOptions?.eventsEntityName}
            videosEntityName={appState.settings.generalOptions?.videosEntityName}
            page404={appState.settings.generalOptions?.page404}
            pageRegistration={appState.settings.generalOptions?.pageRegistration}
            searchPage={appState.settings.generalOptions?.searchPage}
            productContactFormUrl={appState.settings.generalOptions?.productContactFormUrl}
            privacyPolicyPage={appState.settings.generalOptions?.privacyPolicyPage}
            ifuPage={appState.settings.generalOptions?.ifuPage}
            translations={appState.settings.translations}
            user={appState.user}
            siteDomains={appState.settings.siteDomains}
            siteInfo={appState.settings.siteInfo}
            algoliaSettings={appState.settings.algoliaSettings}
            language={appState.language}
            announcement={appState.announcement}
            hcpWarning={appState.hcpWarning}
            appDownloadPrompt={appState.appDownloadPrompt}
            pageRequestPasswordResetUrl={appState.settings.generalOptions?.pageRequestPasswordResetUrl}
            pageResetPasswordUrl={appState.settings.generalOptions?.pageResetPasswordUrl}
            pageVerifyAccountUrl={appState.settings.generalOptions?.pageVerifyAccountUrl}
            shopPage={appState.settings.generalOptions?.shopPage}
            myContentPage={appState.settings.generalOptions?.myContentPage}
            setCacheType={() => {}}
            setStatusCode={() => {}}
            deploymentEnvironment={appState.settings.deploymentEnvironment}
            recaptchaSiteKey={appState.settings.recaptchaSiteKey}
            isPdfGenerator={appState.settings.isPdfGenerator}
            newsletterSignupPage={appState.settings.generalOptions?.newsletterSignupPage}
            pdfBuilderPage={appState.settings.generalOptions?.pdfBuilderPage}
            contactUsPage={appState.settings.generalOptions?.contactUsPage}
            careerPage={appState.settings.generalOptions?.careerPage}
            productsListingPage={appState.settings.generalOptions?.productsListingPage}
            eventsListingPage={appState.settings.generalOptions?.eventsListingPage}
            hcpEventsListingPage={appState.settings.generalOptions?.hcpEventsListingPage}
            blogsNewsListingPage={appState.settings.generalOptions?.blogsNewsListingPage}
            hcpBlogsNewsListingPage={appState.settings.generalOptions?.hcpBlogsNewsListingPage}
            videosListingPage={appState.settings.generalOptions?.videosListingPage}
            headerSettings={appState.settings.headerSettings}
            productInformation={appState.settings.generalOptions?.productInformation}
            // Atos Care
            shop={{
                basketPage: appState.settings.generalOptions?.shop?.basketPage,
                orderConfirmationPage: appState.settings.generalOptions?.shop?.orderConfirmationPage,
            }}
        >
            <Router url={window.location.pathname}>
                <MetaProvider>
                    <App />
                </MetaProvider>
            </Router>
        </AppContextProvider>
    ),
    // @ts-ignore
    document.getElementById('root')
);
