import { createSignal, onMount, Show, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { Heading } from '../../../ui-components/heading/heading';
import { Text } from '../../../ui-components/text/text';
import { getBackgroundImageSrcData, ImageSrcData } from '../../image-component/image-component';
import { CustomCardType } from '../card-slider';
import { StyledCardWrapper, StyledImage, StyledImageWrapper, StyledPlayIconWrapper, StyledTextWrapper, StyliedSliderCardTitle } from './custom-slider-video-card.styles';
import { PlayIcon } from '../../icons-library/play';

type Props = {
    card: CustomCardType;
    onClick: () => void;
};

export const CustomVideoSliderCard = (props: Props) => {
    const { imagesServiceUrl, supportedImageFormats, } = useContext(AppContext);
    const [ backgroundImageSrcData, setBackgroundImageSrcData ] = createSignal<ImageSrcData>();


    onMount(() => {
        if (props?.card?.image?.url) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.card.image.url,
                width: 500, // Assume mobile from the start
                height: 'AUTO',
            });

            setBackgroundImageSrcData(backgroundImage);
        }
    });

    return (
        <div>
            <StyledCardWrapper cover={true} onClick={() => props.onClick()}>
                <Show when={props?.card?.image?.url}>
                    <StyledImageWrapper>
                        <StyledImage src={backgroundImageSrcData()?.original} alt={props.card.image?.altText || props.card.image?.title || ''} title={props.card.image?.title || ''} />
                        <StyledPlayIconWrapper>
                            <PlayIcon width={5} height={5} fill='rgba(255,255,255,0.5)' />
                        </StyledPlayIconWrapper>
                    </StyledImageWrapper>
                </Show>
                <StyledTextWrapper cover={true}>
                    <Show when={props.card?.heading}>
                        <StyliedSliderCardTitle>
                            <PlayIcon />
                            <Heading tag="h4" variant={'bigGray'}>
                                {props.card.heading}
                            </Heading>
                        </StyliedSliderCardTitle>
                    </Show>
                    <Show when={props.card?.text}>
                        <Text fontSize="normal" lineLimit={6}>
                            {props.card.text}
                        </Text>
                    </Show>
                </StyledTextWrapper>
            </StyledCardWrapper>
        </div>
    );
};
