import { ExpandableContainer } from '../expandable-container/expandable-container';
import theme from '../../style/theme';
import { styled } from 'solid-styled-components';
import { FilterListProps } from './filter-list-types';
import { ErrorCatcher } from '../../tools/error-catcher';

const StyledList = styled.ul`
    padding-left: 1.5rem;
    ${theme.mediaQueries.mobileMax} {
        padding-left: unset;
    }
`;

export const FilterList = (props: FilterListProps) => {
    return (
        <ErrorCatcher componentName='Products filter list'>
            <ExpandableContainer 
                ref={props.ref}
                isDropdown={props.isDropdown}
                chevronColor={theme.palette.red}
                initialOpen={props.initialOpen === false ? false : true}
                customHeadingRowCss={`
                    border-bottom: 1px solid ${theme.palette.gray};
                    margin-bottom: 1.4rem;
                    padding-top: 2rem;
                    padding-bottom: 0.3rem;
                    ${props.isMobile ? '' : 'padding-right: 1rem;'}
                `}
                headingProps={{
                    noBlockSpacing: true,
                    tag: 'h3',
                    variant: props.isMobile ? 'medium' : 'tinyGray',
                    children: props.listHeading,
                }}
            >
                <StyledList>
                    {props.children}
                </StyledList>

            </ExpandableContainer>
        </ErrorCatcher>
    );
};
