
import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

type CustomProps = SvgIconProps & {
    rotate?: 'right' | 'left' | 'up' | 'down';
    bold?: boolean;
};

const defaultProps: CustomProps = {
    height: 0.65,
    width: 1.2,
    viewBox: '0 0 14 10',
    fill: 'none',
    stroke: 'currentColor',
    rotate: 'down',
    bold: true,
};

export const ChevronUpBoldIcon = (componentProps: CustomProps) => {
    const props = mergeProps(defaultProps, componentProps);

    let rotation = '';

    switch (props.rotate) {
        case 'right':
            rotation = 'rotate(270 7 4.5)';
            break;
        case 'up':
            rotation = 'rotate(180 7 4.5)';
            break;
        case 'left':
            rotation = 'rotate(90 7 4.5)';
            break;
        default:
            rotation = 'rotate(0 7 4.5)';
            break;
    }

    return (
        <SvgIcon {...props}>
            <path 
                d="M12 2L7.20832 7L2 2"
                stroke-width={props.bold ? 2 : 1}
                stroke-linecap="round"
                fill='none'
                transform={rotation}
            />
        </SvgIcon>
    );
};
