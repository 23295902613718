import { Switch, Match, Show } from 'solid-js';
import theme from '../../style/theme';
import { DocumentTypeFlagTypes } from './document-type-flag-types';
import {
    StyledDocumentTypeFlag,
    StyledDocumentTypeFlagTextLarge,
    StyledDocumentTypeFlagTextSmall,
    StyledIconPlaceholder,
} from './document-type-flag.styles';
import removeAmpersand from '../../tools/remove-ampersand';

export const DocumentTypeFlag = (props: DocumentTypeFlagTypes) => {
    const getFillColors = () => {
        const fillColor = props.type === 'event' ? theme.palette.pink : theme.palette.darkGray;
        const secondaryFillColor = props.type === 'event' ? theme.palette.red : theme.palette.darkestGray;

        return { fillColor, secondaryFillColor };
    };

    const getLabelName = () => {
        let labelName = '';

        if (props.type === 'event') {
            labelName = props.customText || props.date || props.type;
        } else {
            labelName = props.customText || props.type;
        }

        labelName = labelName.length > 27 ? `${labelName.slice(0, 27)}...` : labelName;
        return removeAmpersand(labelName);
    };

    const PennantIcon = () => (
        <svg viewBox="9.1 5.9 37.8 100" style={{transform: props.displaySide === 'left' ? 'scale(-1, 1) translateX(-14px)' : ''}}>
            <g>
                <path
                    d="M28.3,20.9v49.9H9.1V8h6.3c3.5,0,6.7,1.3,9.2,3.8h0c0.9,0.9,1.7,2,2.3,3.1c0.1,0.2,0.2,0.3,0.2,0.5v0C27.9,17.1,28.3,19,28.3,20.9z"
                    fill={getFillColors().fillColor}
                />
                <polygon points="25.5,74.8 11,74.8 11,89.2" fill={getFillColors().secondaryFillColor} />
                <path
                    d="M15.4,6H9.1v2h6.3c3.5,0,6.7,1.3,9.2,3.8c0.9,0.9,1.7,2,2.3,3.1c0.1,0.2,0.2,0.3,0.2,0.5v0c0.8,1.7,1.2,3.6,1.2,5.5v49.9H9.1V94l21.3-21.2V20.9C30.3,12.7,23.6,6,15.4,6z M11,89.2V74.8h14.5L11,89.2z"
                    fill="white"
                />
            </g>
        </svg>
    );

    return (
        <Switch>
            <Match when={props.size === 'small'}>
                <StyledDocumentTypeFlag>
                    <StyledDocumentTypeFlagTextSmall backgroundColor={getFillColors().fillColor}>
                        {getLabelName()}
                    </StyledDocumentTypeFlagTextSmall>
                    <StyledIconPlaceholder>
                        <PennantIcon />
                    </StyledIconPlaceholder>
                </StyledDocumentTypeFlag>
            </Match>

            <Match when={props.size === 'large'}>
                <StyledDocumentTypeFlag displaySide={props.displaySide} size={'large'}>
                    <Show when={props.displaySide === 'left'}>
                        <StyledIconPlaceholder size={'large'}>
                            <PennantIcon />
                        </StyledIconPlaceholder>
                    </Show>
                    <StyledDocumentTypeFlagTextLarge 
                        backgroundColor={getFillColors().fillColor}
                        displaySide={props.displaySide}
                    >
                        {getLabelName()}
                    </StyledDocumentTypeFlagTextLarge>
                    <Show when={props.displaySide !== 'left'}>
                        <StyledIconPlaceholder size={'large'}>
                            <PennantIcon />
                        </StyledIconPlaceholder>
                    </Show>
                </StyledDocumentTypeFlag>
            </Match>
        </Switch>
    );
};
