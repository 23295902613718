import { gql } from 'graphql-request';
import { Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { RegistrationForm, UserServiceResponse } from './registration-form';
import { careFields } from './fields';
import { Button } from '../../ui-components/button/button';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { Heading } from '../../ui-components/heading/heading';
import { formStore } from './registration-form-store';
import { BasketContext } from '../basket/basket-context-provider';

export const createCareUserMutation = gql`
    mutation createCareUser(
        $email: String!
        $firstName: String!
        $lastName: String!
        $street: String
        $city: String
        $zip: String
        $country: String!
        $password: String!
        $site: String!
        $isCaregiver: Boolean
        $patientEmail: String
        $patientName: String
        $patientStreet: String
        $patientPostcode: String
        $patientCity: String
        $captchaToken: String!
        $referer: String!
        $company: String
    ) {
        createCareUser(
            email: $email
            firstName: $firstName
            lastName: $lastName
            street: $street
            city: $city
            zip: $zip
            country: $country
            password: $password
            site: $site
            isCaregiver: $isCaregiver
            patientEmail: $patientEmail
            patientName: $patientName
            patientStreet: $patientStreet
            patientPostcode: $patientPostcode
            patientCity: $patientCity
            captchaToken: $captchaToken
            referer: $referer
            company: $company
        ) {
            status
            message
        }
    }
`;

export const CareRegistrationWrapper = () => {
    const { productsListingPage, localize, shop, graphQlClient, siteInfo } = useContext(AppContext);
    const { basket } = useContext(BasketContext);

    const createUser = async (captchaToken: string): Promise<UserServiceResponse> => {
        const createUserArgs = {
            site: siteInfo.siteSlug,
            clientOrigin: window.location.origin,
            referer: window.location.href,
            email: formStore.fields.email,
            firstName: formStore.fields.firstname,
            lastName: formStore.fields.lastname,
            street: formStore.fields.street,
            city: formStore.fields.city,
            zip: formStore.fields.zip,
            country: formStore.fields.country,
            password: formStore.fields.password,
            isCaregiver: formStore.fields[ 'is-caregiver' ],
            patientEmail: formStore.fields[ 'patient-email' ],
            patientName: formStore.fields[ 'patient-name' ],
            patientStreet: formStore.fields[ 'patient-street' ],
            patientPostcode: formStore.fields[ 'patient-postcode' ],
            patientCity: formStore.fields[ 'patient-city' ],
            company: formStore.fields.company,
            captchaToken,
        };

        return await graphQlClient(createCareUserMutation, createUserArgs, false);
    };

    const NavigationComponents = () => {
        return (
            <StyledFlexRow gap='2rem' flexWrap='wrap' justifyContent='center'>
                <Show when={productsListingPage.url}>
                    <Button
                        label={localize('all-products', 'All products')}
                        url={productsListingPage.url}
                        isLink={true}
                        variant='secondary'
                        noBlockSpacing
                    />
                </Show>
                <Show when={basket.order.orderDetails.length > 0}>
                    <Button
                        label={localize('view-basket', 'View basket')}
                        url={shop.basketPage.url}
                        isLink={true}
                        variant='primary'
                        noBlockSpacing
                    />
                </Show>
            </StyledFlexRow>
        );
    };

    const SuccessMessageComponents = () => {
        const successRegisteringUser = localize('registration-successful', 'Successfully created user account with email');
        const message = localize('registration-successful', `${successRegisteringUser} ${formStore.fields.email}.`);

        return (
            <Heading tag='h4' variant='smallGray' noBlockSpacing>
                {message}
            </Heading>
        );
    };

    return (
        <RegistrationForm 
            createUser={(token) => createUser(token)}
            fields={careFields}
            navigationComponents={<NavigationComponents/>}
            successMessageComponents={<SuccessMessageComponents/>}
        />
    );
};
