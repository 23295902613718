import { styled } from 'solid-styled-components';
import theme, { palette } from '../../style/theme';
import { StyledDropdownItemProps, StyledResultsDropdownProps } from './search-types';

export const StyledSearchWrapper = styled.div<{ adjustForHcpHeader: boolean }>`
    container-type: inline-size;

    height: calc(100vh - 10rem);
    padding-top: 5rem;
    margin: 0 auto;
    min-height: 60rem;

    ${p => p.adjustForHcpHeader ? `
        ${theme.mediaQueries.desktopMin} {
            margin-top: 8rem;
        }
            
        margin-top: 6rem;
    ` : ''}

    ${theme.mediaQueries.tabletMax} {
        height: auto;
    }
`;

export const StyledHeadingsSection = styled.div`
    padding: 0 5rem;

    ${theme.mediaQueries.mobileMax} {
        padding: 0 2rem;
    }
`;

export const StyledSearchFormWrapper = styled.div<StyledDropdownItemProps>`
    margin-top: 5rem;
    position: relative;
    border-radius: 1rem 1rem 0 0;
    height: 6rem;
    ${theme.mediaQueries.tabletMax} {
        height: 17rem;
    }
    ${theme.mediaQueries.mobileMax} {
        height: auto;
    }
`;

export const StyledSearchForm = styled.form`
    display: flex;
    border: 1px solid ${palette.gray};
    background: white;
    border-radius: 1rem;
    position: relative;
    z-index: 1;
`;

const inputFontSize = `
    font-size: 1.3rem;
    line-height: 2.1rem;
`;

export const StyledSearchInput = styled.input`
    text-overflow: ellipsis;
    flex: 1;
    padding: 2rem;
    padding-right: 0;
    border-radius: 1rem;
    border: none;
    &:focus {
        outline: none;
    }
    ${inputFontSize};
`;

export const StyledButton = styled.button`
    padding: 0 1.5rem;
    border: none;
    outline: none;
    background: none;
    svg {
        fill: ${palette.red};
    }
    cursor: pointer;
`;

export const StyledResultsDropdown = styled.ul<StyledResultsDropdownProps>`
    position: relative;
    width: 100%;
    padding: 1.5rem 2rem;
    border-radius: 0 0 1rem 1rem;
    border: 1px solid ${palette.gray};
    border-top: none;
    top: -1rem;

    background: white;
    display: ${(p) => (p.isDropdownVisible ? 'block' : 'none')};
`;

export const StyledDropdownItem = styled.li<any>`
    cursor: pointer;
    margin: 0.5rem 0;
    &:hover {
        color: ${palette.pink};
    }
    ${inputFontSize};

    ${(p) =>
        p.noResults
            ? `
        cursor: default;
        &:hover {
        color: inherit;
    }
    `
            : ''}
`;

export const StyledCategoriesWrapper = styled.div`
    text-align: center;
    margin-top: 5rem;
    ${theme.mediaQueries.mobileMax} {
        margin-top: 2rem;
    }
`;

export const StyledCategories = styled.div``;

export const StyledSearchResultsHeadingsWrapper = styled.div`
    container-type: inline-size;
`;

export const StyledSearchResultsHeadingsSection = styled.div`
    display: flex;
    margin: 5rem auto;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    h2,
    button {
        margin-block: 0;
    }

    ${theme.mediaQueries.mobileMax} {
        flex-direction: column;
        width: 100%;
        margin: 0;
    }
`;

export const StyledRedDivider = styled.div`
    width: 1px;
    height: 100%;
    background: red;

    ${theme.mediaQueries.mobileMax} {
        height: 1px;
        width: 100%;
    }
`;
