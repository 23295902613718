import { useContext, createSignal, Show } from 'solid-js';
import { gql } from 'graphql-request';
import { Grid } from '../../grid-system/grid/grid';
import { StyledButtonWrapper, StyledContentHubContainer, StyledLinksContainer, StyledLinksInner, StyledRightPanel } from './content-hub.styles';
import { createGroupedBlogAndNewsCard } from '../blogs-news/blogs-news-component';
import { BlogNewsData, BlogNewsListCardData } from '../blog-news/blog-news';
import { EventData, EventListCardData } from '../event/event-types';
import { PostCardPlaceholder } from './post-card-placeholder';
import { ContentHubProps, CustomPostLink } from './content-hub-types';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import { Button } from '../../ui-components/button/button';
import { ErrorCatcher } from '../../tools/error-catcher';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { createGroupedEventCard } from '../events/helpers/events-card-helper';
import { isOnDemandEvent } from '../events/helpers/is-on-demand-event';
import { mapBlogMetaToListCardData } from '../blogs-news/map-blog-meta-to-list-card-data';
import { mapEventMetaToListCardData } from '../events/helpers/map-event-meta-to-list-card-data';

const GET_POSTS_QUERY_WITH_TAGS = gql`
    blogPostsWithTags: resources(
        type: "blog-and-news"
        tags: $blogTags
        limit: $limit
        order: DESC
    ) {
        key
        meta {
            postTitle
            categories
            description
            featuredImage
            extra
        }
    }
`;

const GET_POSTS_QUERY = gql`
    blogPosts: resources(
        type: "blog-and-news"
        limit: $limit
        order: DESC
    ) {
        key
        meta {
            postTitle
            categories
            description
            featuredImage
            extra
        }
    }
`;

const GET_EVENTS_QUERY_WITH_TAGS = gql`
    eventsWithTags: resources(
        type: "event",
        tags: $eventTags,
        limit: $limit,
        order: DESC,
        fromDate: $fromDate
    ) {
        key
        meta {
            postTitle
            categories
            description
            featuredImage
            extra
        }
    }
`;

const GET_EVENTS_QUERY = gql`
    events: resources(
        type: "event",
        limit: $limit,
        order: DESC,
        fromDate: $fromDate
    ) {
        key
        meta {
            postTitle
            categories
            description
            featuredImage
            extra
        }
    }
`;

export const GET_CUSTOM_POSTS_QUERY = gql`
    query GetCustomPosts(
        $keys: [String],
    ) {
        posts: resources(
            keys: $keys
        ) {
            content
        }
    }

`;

type QueryVariables = {
    limit: number;
    blogTags?: string[];
    eventTags?: string[];
    fromDate?: string
};

export const ContentHub = (props: ContentHubProps) => {
    const { createCachedResource, localize, blogAndNewsEntityName, eventsEntityName } = useContext(AppContext);
    const [err, setErr] = createSignal(false);

    if (props.mixedContent && props.cards.length === 0) {
        return null;
    }
    
    let blogTags: string[] = [];
    let eventTags: string[] = [];

    const shouldFetchByDate = props.mixedContent
        ? props.cards.filter((card) => card?.posttype?.value === 'event').length > 0
        : props.contentType?.posttype?.value === 'event';

    const eventTagsPlaceholder = 'EVENT_TAGS';
    const blogTagsPlaceholder = 'BLOG_TAGS';

    // Create a graphql query to load everything at once, both events and blog-and-news
    let query = `query GetEntries(
        $limit: Int,
        ${eventTagsPlaceholder}
        ${blogTagsPlaceholder}
        ${shouldFetchByDate ? '$fromDate: String' : ''}
    ) { `;

    const prepareTagsAndQuery = (
        postType: string,
        tagsPlaceholder: string,
        getPostsQuery: string,
        getPostsQueryWithTags: string,
        queryPlaceholderReplacement: string
    ) => {
        const posts = props.cards
            .filter(card => !card.customPostLink?.url)
            .filter(card => card.posttype?.value === postType);
    
        // Graphql doesn't like empty variables, so we need to remove the placeholder
        if (posts.length === 0) {
            query = query.replace(tagsPlaceholder, '');
            return;
        }   
    
        const postsWithoutSpecificTags = posts.find(card => !card.tag?.slug);
    
        /**
         * If we have a card without a specific tag we need to fetch all posts, because any other card
         * with a tag would limit the results to only those with that tag.
         */
        if (postsWithoutSpecificTags) {
            query += getPostsQuery;
        }
    
        const tags = Array.from(new Set(posts
            .filter(card => card.tag?.slug)
            .map(card => card.tag?.slug) as string[]));
    
        if (tags.length > 0) {
            query = query.replace(tagsPlaceholder, queryPlaceholderReplacement);            
            query += getPostsQueryWithTags;

            // Store the tags for later use in the variables
            postType === 'blog-and-news' 
                ? blogTags = tags 
                : eventTags = tags;

            return;
        }

        // Graphql doesn't like empty variables, so we need to remove the placeholder
        query = query.replace(tagsPlaceholder, '');
    };

    /**
     * If we have mixed content we need to massage the graphql query string to include the
     * correct queries based on the cards we have.
     */
    if (props.mixedContent) {
        prepareTagsAndQuery(
            'blog-and-news',
            blogTagsPlaceholder,
            GET_POSTS_QUERY,
            GET_POSTS_QUERY_WITH_TAGS,
            '$blogTags: [String],'
        );

        prepareTagsAndQuery(
            'event',
            eventTagsPlaceholder,
            GET_EVENTS_QUERY,
            GET_EVENTS_QUERY_WITH_TAGS,
            '$eventTags: [String],'
        );
    } else {
        if (props.contentType?.posttype?.value === 'blog-and-news') {
            blogTags = props.contentType.tag?.slug ? [props.contentType.tag.slug] : [];

            query = query.replace(blogTagsPlaceholder, '$blogTags: [String],');
            query = query.replace(eventTagsPlaceholder, ''); // Graphql doesn't like empty variables
            query += GET_POSTS_QUERY_WITH_TAGS;
        } else {
            eventTags = props.contentType.tag?.slug ? [props.contentType.tag.slug] : [];

            query = query.replace(eventTagsPlaceholder, '$eventTags: [String],');
            query = query.replace(blogTagsPlaceholder, ''); // Graphql doesn't like empty variables
            query += GET_EVENTS_QUERY_WITH_TAGS;
        }
    }

    query += ' } ';

    const variables: QueryVariables = { 
        limit: 20, // Even though we only show 3 cards, we load an arbitrarily higher amount to maximize change that we have enough after we've filtered out the ones we don't want
        fromDate: new Date().toDateString()
    };

    variables.eventTags = eventTags;
    variables.blogTags = blogTags;

    const [entries] = createCachedResource(query, variables, true);


    /**
     * Fetch data for custom post links using the collected keys.
     * First ensure we have keys - request with empty keys will fetch everything in database
     */
    const customPostLinks = props.cards
        .map(card => card.customPostLink?.url)
        .filter(url => url);

    let customPostLinkEntries: any;

    if (customPostLinks.length) {
        [ customPostLinkEntries ] = createCachedResource(
            GET_CUSTOM_POSTS_QUERY,
            { keys: customPostLinks },
            true
        );
    }


    const audienceFilter = (post: BlogNewsData | EventData) => {
        if (!props.audience || props.audience === 'all') {
            return true;
        }
        if (props.audience === 'hcp') {
            return post?.isHealthcareProfessional;
        } else {
            return !post?.isHealthcareProfessional;
        }
    };


    const renderCustomLinkCard = (customPostLink: CustomPostLink, type: string, largeCard: boolean) => {

        if (customPostLinkEntries.loading) {
            return <PostCardPlaceholder/>;
        }

        // Find the corresponding entry from customPostLinkEntries based on the URL
        const cardData = customPostLinkEntries()?.posts.find((entry: any) => entry.content?.permalink === customPostLink.url);
        if (!cardData) {
            return largeCard
                ? <Heading tag="h3" variant="small">{localize('no-content-found', 'No content found')}</Heading>
                : null;
        }
        if (type === 'blog-and-news') {
            return createGroupedBlogAndNewsCard({ post: cardData, largeCard });
        } else if (type === 'event') {
            return createGroupedEventCard({ event: cardData, largeCard });
        }

    };

    // Pick the correct card from the list of entries that we load. It has to take into account type and tags and placement
    const renderCard = (cardIndex: number) => {
        if (entries.loading) {
            return <PostCardPlaceholder/>;
        }

        if (entries.error) {
            setErr(true);
            return;
        }

        const currentCard = props.cards[cardIndex];


        let tag: string;
        let type: string | undefined;
        
        if (props.mixedContent) {
            // 3 cards with any combination of blog-and-news and events with tags
            tag = currentCard?.tag?.slug || 'NO_TAG';
            type = currentCard?.posttype?.value;
        } else {
            // All 3 cards are of the same type
            tag = props.contentType?.tag?.slug || 'NO_TAG';
            type = props.contentType?.posttype?.value;
        }

        if (!type) {
            return null;
        }


        const largeCard = cardIndex === 0;

        const customPostLink = currentCard?.customPostLink;
        if (customPostLink?.url) {
            return renderCustomLinkCard(customPostLink, type, largeCard);
        }

        // No custom post link
        const data = type === 'blog-and-news'
            ? [
                ...entries()?.blogPosts || [],
                ...entries()?.blogPostsWithTags || []
            ]
            : [
                ...entries()?.events || [],
                ...entries()?.eventsWithTags || []
            ];

        const filteredData: (EventListCardData | BlogNewsListCardData | null)[] = data
            .map((data: any) => {
                if (type === 'blog-and-news') {
                    return mapBlogMetaToListCardData(data, blogAndNewsEntityName);
                }
                
                if (type === 'event') {
                    return mapEventMetaToListCardData(data, eventsEntityName);
                }

                return null;
            })
            .sort((a, b) => {
                const dateA = a?.date ? new Date(a.date).getTime() : 0; // Use 0 as default if a.date is undefined
                const dateB = b?.date ? new Date(b.date).getTime() : 0; // Use 0 as default if b.date is undefined
                if (type === 'event') {
                    return dateA - dateB;
                } else {
                    return dateB - dateA;
                }
            })
            .filter((content: any) => audienceFilter(content))
            .filter((content: any) => {
                if (type === 'blog-and-news') {
                    return true;
                }
                
                return eventsFilter(content);
            });

        if (!filteredData) {
            return largeCard 
                ? <Heading tag="h3" variant="small">{localize('no-content-found', 'No content found')}</Heading>
                : null;
        }

        let card = filteredData[cardIndex];

        if (props.mixedContent) {
            /**
             * Create a list of the content types + tag to render, in the order they appear in the cards array.
             * E.g. ['blog-and-news-hcp', 'event-live', 'blog-and-news-NO_TAG']
             */
            const contentTypeTagsMap = props.cards.map(card => `${card?.posttype?.value}-${card.tag?.slug || 'NO_TAG'}`);

            /**
             * Count how many cards of the same type we've already rendered - this gives us the index to pick the next data item from.
             * E.g. if we're on index 2 we've rendered one blog-and-news card, so we should pick the 2nd blog-and-news item from the list of blogPosts (i.e. index 1)
             */
            const priorTypeTagCardCount = contentTypeTagsMap
                .slice(0, cardIndex)
                .filter((cardTypeTag) => cardTypeTag === `${type}-${tag}`).length;

            /**
             * Find the data item to render based on the tag and the count of the same type of card we've already rendered.
             */
            const filtered = filteredData
                .filter((content: any) => {
                    const tags = content?.tags || [];       
                    return tag === 'NO_TAG' ? true : tags.includes(tag);
                })
                .find((_: any, index: number) => index === priorTypeTagCardCount);

            if (!filtered) {
                card = null;
            } else {
                card = filtered;
            }
        }
        
        if (!card) {
            return null;
        }

        if (type === 'blog-and-news') {
            return createGroupedBlogAndNewsCard({ post: card, largeCard });
        } else if (type === 'event') {            
            return createGroupedEventCard({ event: card, largeCard });
        }
    };

    const eventsFilter = (post: EventData) => {
        if (post?.isFullyBooked) return false;
        if (isOnDemandEvent(post)) return false;
        return true;
    };

    function withLinks() {
        return Boolean(props.link1?.url || props.link2?.url);
    };

    return (
        <ErrorCatcher componentName='Content hub'>
            <StyledContentHubContainer class='content-hub' withLinks={withLinks()}>
                <Show when={props.eyebrowHeading}>
                    <Heading tag="h3" variant="small"> {props.eyebrowHeading} </Heading>
                </Show>
                <Show when={props.heading}>
                    <Heading tag="h2" variant="xlarge"> {props.heading} </Heading>
                </Show>
                <Show
                    when={!err()}
                    fallback={
                        <Text fontSize='normal'>{localize('error-has-occurred', 'An error has occurred, please try again later')}</Text>
                    }
                >
                    <Grid
                        templateShorthand={[7, 5]}
                        responsive={{
                            largeDesktop: [6, 6],
                            smallDesktop: [6, 6],
                            tablet: [12]
                        }}
                    >
                        {renderCard(0)}
                        <Grid
                            templateShorthand={[5]} 
                            responsive={{
                                largeDesktop: [6],
                                smallDesktop: [6],
                                tablet: [12]
                            }}
                        >
                            <StyledRightPanel>
                                <div>{renderCard(1)}</div>
                                <div>{renderCard(2)}</div>
                                <StyledLinksContainer>
                                    <StyledLinksInner>
                                        <Show when={props.link1?.url}>
                                            <Button variant="tertiary" label={props.link1?.title} arrow={true} isLink={true} url={props.link1?.url || '#'}/>
                                        </Show>
                                        <Show when={props.link2?.url}>
                                            <StyledButtonWrapper>
                                                <Button variant="tertiary" label={props.link2?.title} arrow={true} isLink={true} url={props.link2?.url || '#'}/>
                                            </StyledButtonWrapper>
                                        </Show>
                                    </StyledLinksInner>
                                </StyledLinksContainer>
                            </StyledRightPanel>
                        </Grid>
                    </Grid>
                </Show>
            </StyledContentHubContainer>
        </ErrorCatcher>
    );
};


ContentHub.parseProps = (atts: any) => {
    const type = atts.contentType?.posttype?.value;
    const mixedContent = type === 'mix' ? true : false;
    const cards = mixedContent ? [ atts.card1, atts.card2, atts.card3 ] : [];
    
    return {
        eyebrowHeading: atts.eyebrowHeading,
        heading: atts.heading,
        mixedContent,
        contentType: atts.contentType,
        cards: cards.filter(card => card?.posttype?.value),
        link1: atts.link1,
        link2: atts.link2,
        audience: atts.audience,
    };
};
