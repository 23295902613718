import { createSignal, For, Match, Switch, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Grid } from '../../grid-system/grid/grid';
import { Heading } from '../../ui-components/heading/heading';
import theme, { palette } from '../../style/theme';
import { BlogNewsListCardData, Category } from '../blog-news/blog-news';
import { StyledCategoriesContainer, StyledCategoriesContainerMobile, StyledCategoriesHeadingDesktop, StyledCategoriesList, StyledCategoryItem } from './blogs-news.styles';
import { PostCard } from '../post-card/post-card';
import { ExpandableContainer } from '../../ui-components/expandable-container/expandable-container';
import { Section } from '../../grid-system/section/section';
import { PageContext } from '../page/page-context-provider';
import { useLocation, useNavigate, useSearchParams } from '@solidjs/router';
import { ErrorCatcher } from '../../tools/error-catcher';

type TopCategory = {
    slug: string;
    name: string;
    children?: Category[];
};

export const gridSettings = {
    container: {
        templateShorthand: [4, 4, 4],
    },
};

const extractCategories = (accCategories: TopCategory[], categories?: Category[]) => {
    if (!categories) {
        return accCategories;
    }

    let uniqueCategories = [...accCategories];
    
    categories.forEach((category) => {            
        if (!uniqueCategories.find((uc) => uc.slug === category.slug)) {
            uniqueCategories.push({
                slug: category.slug,
                name: category.name,
            });
        }

        if (category?.children && category?.children.length > 0) {
            uniqueCategories = [...extractCategories(uniqueCategories, category.children)];
        }
    });

    return uniqueCategories;
};

const filterByAudience = (post: BlogNewsListCardData, audience?: string) => {
    switch (audience) {
        case 'all': return true;
        case 'patients': return !post?.isHealthcareProfessional;
        case 'hcp': return post?.isHealthcareProfessional;
        default: return true;
    };
};

export const extractTopCategories = (posts: BlogNewsListCardData[], audience?: string) => {
    const allCategories = posts
        .filter((post) => filterByAudience(post, audience))
        .reduce((accCategories: TopCategory[], post) => {
            accCategories = [...extractCategories(accCategories, post.categories)];

            return accCategories;
        }, []);

    return allCategories;
};

export const createGroupedBlogAndNewsCard = (props: { post: BlogNewsListCardData, largeCard?: boolean}) => {
    return createBlogAndNewsCard({
        post: props.post,
        inGrouping: true,
        largeCard: props.largeCard,
    });
};

export const createBlogAndNewsCard = (props: { post: BlogNewsListCardData, inGrouping?: boolean, largeCard?: boolean }) => {
    const AppState = useContext(AppContext);

    const {userState, pageRegistration } = AppState;

    const hideTypeFlag = props.post?.isHealthcareProfessional 
        && !props.post?.isVisibleForPublic 
        && !userState.user 
        && pageRegistration.url !== '';

    const content = {
        ...props.post,
        type: props.post?.categories?.length ? props.post?.categories[0]?.name : AppState.localize('blog', 'Blog'),
    };

    return (
        <PostCard
            orientation={'horizontal'}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data={content} 
            hideTypeFlag={hideTypeFlag}
            inGrouping={props.inGrouping}
            largeCard={props.largeCard}
        />
    );
};



export const BlogsNewsComponent = (props: BlogsNewsComponentProps) => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { viewport, localize } = useContext(AppContext);
    const { withSidebarMenu } = useContext(PageContext);
    const [topCategories, setTopCategories] = createSignal<TopCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = createSignal<TopCategory>({
        slug: searchParams.category || 'all',
        name: ''
    });

    const categoryName = () => {
        const category = topCategories().find((category) => {
            return category.slug === selectedCategory().slug;
        });
        
        if (!category) {
            return '';
        }

        return category.name;
    };

    const filteredPosts = () => {
        const matchCategory = (targetSlug: string, categories?: Category[]) => {
            if (targetSlug === 'all') {
                return true;
            }

            if (!categories) {
                return false;
            }

            const foundMatch = categories.find((category) => {
                if (category.slug === targetSlug) {
                    return true;
                }

                if (category.children && category.children.length > 0) {
                    return matchCategory(targetSlug, category.children);
                }

                return false;
            });

            if (!foundMatch) {
                return false;
            }

            return true;
        };

        const matches = props.posts
            .filter((post) => filterByAudience(post, props.audience))
            .filter((post) => {
                return matchCategory(selectedCategory().slug, post.categories);
            });
        
        return matches;
    };

    let expandableContainer: HTMLDivElement | undefined;
    const handleCategoryClick = (category: TopCategory | null) => {
        const categoryToUse = category || { slug: 'all', name: 'All' };
        setSelectedCategory(categoryToUse);
        expandableContainer?.click();
        navigate(`${location.pathname}?category=${categoryToUse.slug}`, { scroll: false });
    };

    const extractedTopCategories = extractTopCategories(props.posts, props.audience);
    setTopCategories(extractedTopCategories);

    const renderCategoriesList = () => {
        return (
            <StyledCategoriesList>
                <li>
                    <StyledCategoryItem underlined={selectedCategory().slug === 'all'} onClick={() => handleCategoryClick(null)}>
                        <Heading tag="h3" variant="tinyRed" noBlockSpacing={true}>
                            {localize('all', 'All')}
                        </Heading>
                    </StyledCategoryItem>

                    <For each={topCategories()}>
                        {(topCategory: TopCategory) => (
                            <StyledCategoryItem underlined={selectedCategory()?.slug === topCategory.slug} onClick={() => handleCategoryClick(topCategory)}>
                                <Heading tag="h3" variant="tinyRed" noBlockSpacing={true}>
                                    {topCategory.name}
                                </Heading>
                            </StyledCategoryItem>
                        )}
                    </For>
                </li>
            </StyledCategoriesList>
        );
    };

    return (
        <ErrorCatcher componentName='Blog and news component'>
            <Section
                templateShorthand={[12]}
                widthType='bgFull'
                backgroundType='color'
                backgroundValue='white'
                customCss='z-index: 1;'
            >
                <Switch>
                    <Match when={viewport.width > theme.breakpoints.MOBILE}>
                        <StyledCategoriesContainer>
                            <StyledCategoriesHeadingDesktop>
                                <Heading tag="h3" variant="medium" noBlockSpacing={true}>
                                    {localize('categories', 'Categories')}
                                </Heading>
                            </StyledCategoriesHeadingDesktop>
                            {renderCategoriesList()}
                        </StyledCategoriesContainer>
                    </Match>

                    <Match when={viewport.width <= theme.breakpoints.MOBILE}>
                        <StyledCategoriesContainerMobile>
                            <ExpandableContainer
                                ref={expandableContainer}
                                initialOpen={false}
                                chevronColor={palette.red}
                                isDropdown={true}
                                withBorder={true}
                                headingProps={{
                                    noBlockSpacing: true,
                                    tag: 'h3',
                                    variant: 'medium',
                                    children: categoryName() || localize('categories', 'Categories'),
                                }}
                            >
                                {renderCategoriesList()}
                            </ExpandableContainer>
                        </StyledCategoriesContainerMobile>
                    </Match>
                </Switch>
            </Section>

            <Section templateShorthand={[12]} widthType='bgFull' isLayoutSection={withSidebarMenu() ? false : true}>
                <Grid 
                    templateShorthand={withSidebarMenu() ? [6,6] : [4,4,4]}
                    responsive={{mobile: [12], tablet: [12], smallDesktop: [6,6]}}
                >
                    <For each={filteredPosts()}>{(post: BlogNewsListCardData) => {
                        return createGroupedBlogAndNewsCard({post});
                    }}
                    </For>
                </Grid>
            </Section>
        </ErrorCatcher>
    );
};

export type BlogsNewsComponentProps = {
    posts: BlogNewsListCardData[];
    audience?: string;
}
