import { EventListCardData } from '../../event/event-types';
import { PostCard } from '../../post-card/post-card';
import { isPastEvent } from './is-past-event';

export const createGroupedEventCard = (props: { event: EventListCardData; largeCard?: boolean }) => {
    return createEventCard({
        event: props.event,
        inGrouping: true,
        largeCard: props.largeCard,
    });
};



export const createEventCard = (props: { event: EventListCardData; inGrouping?: boolean; largeCard?: boolean }) => {
    const content = {
        ...props.event,
        type: 'event',
        stale: isPastEvent(props.event),
    };
    
    return <PostCard
        orientation={'horizontal'}
        data={content}
        hideTypeFlag={false}
        inGrouping={props.inGrouping}
        largeCard={props.largeCard}
    />;
};
