import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../style/theme';

export const StyledBreadCrumbNav = styled.nav`
    position: relative;
`;

export const StyledBreadcrumbList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const StyledBreadcrumbListItem = styled.li`
    display: flex;
    align-items: center;
`;

export const StyledBreadcrumbSlash = styled.span`
    color: ${theme.palette.gray};
    margin-inline: 0.5rem;
    font-family: ${fontFamilyAtosRegular};
    font-size: 1.3rem;
`;

