import { createEffect, createSignal, Show, useContext } from 'solid-js';
import { styled } from 'solid-styled-components';
import { ImageWordpress } from '../../types/shared';
import { AtosLogoRelative } from '../icons-library/atos-logo-relative';
import { StyledImage } from '../post-card/post-card.styles';
import { getYoutubeVideoId } from './helpers';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { PlayAndCircle } from '../../ui-components/play-and-circle/play-and-circle';

const StyledIconContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
`;

const StyledAligner = styled.div`
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    cursor: pointer;
`;

type VideoImageBeforeCookieProps = {
    videoUrl: string;
    image?: ImageWordpress;
    bgImage?: string;
    withoutBorderRadius?: boolean;
};

export const VideoImageBeforeCookiesAccepted = (props: VideoImageBeforeCookieProps) => {
    const [ imgSrc, setImgSrc ] = createSignal<string | undefined>();

    const { localize } = useContext(AppContext);

    createEffect(() => {
        // If an image has been added to the video in WP, we show that one here.
        let src;
        if (props.bgImage) {
            src = props.bgImage;
        } else {
            if (props.videoUrl?.includes('youtube')) {
                const id = getYoutubeVideoId(props.videoUrl);
                src = `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;
            }
        }

        setImgSrc(src);
    });

    return (
        <>
            <StyledAligner>
                <PlayAndCircle/>
            </StyledAligner>

            <Show
                fallback={(
                    <StyledIconContainer>
                        <AtosLogoRelative />
                    </StyledIconContainer>
                )}
                when={imgSrc()}
            >
                <StyledImage
                    class="styledImage"
                    src={imgSrc()}
                    title={props?.image?.name || localize('video-thumbnail', 'Video Thumbnail')}
                    alt={props?.image?.alt || localize('video-thumbnail', 'Video Thumbnail')}
                    withoutBorderRadius={props.withoutBorderRadius}
                />
            </Show>
        </>
    );
};
