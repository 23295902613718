import { useLocation } from '@solidjs/router';
import { Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Grid } from '../../grid-system/grid/grid';
import { Heading } from '../../ui-components/heading/heading';
import { StyledFlexColumn, StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { EventListCardData } from '../event/event-types';
import { StyledDate } from '../post-card/post-card.styles';
import { StyledEventDate, StyledReadMore } from './list-view-event-card.styles';
import { isPastEvent } from './helpers/is-past-event';
import { formatTextDate } from '../../tools/date-format';
import { removeTrailingSlash } from '../../tools/remove-trailing-slash';
import { isOnDemandEvent } from './helpers/is-on-demand-event';

export const ListViewEventCard = (props: { event: EventListCardData }) => {
    const { localize, userState, pageRegistration, siteInfo } = useContext(AppContext);

    const isHiddenForPublic = () => {
        return props.event?.isHealthcareProfessional 
            && !props.event?.isVisibleForPublic 
            && !userState.user
            && pageRegistration.url !== '';
    };

    const getDateText = () => {
        if (isOnDemandEvent(props.event)) {
            return '';
        }
        return formatTextDate(props.event?.eventDate || '', siteInfo.key);
    };

    const eventLocation = () => {
        if (!props.event?.eventLocation) {
            return '';
        }
        return ' | ' + props.event?.eventLocation;
    };

    const dateAndLocationString = () => {
        return `${props.event.eventTime || ''} ${eventLocation() || ''}`;
    };

    return (
        <Grid templateShorthand={[2, 10]} responsive={{ mobile: [12], tablet: [12] }} inheritParentGrid={true} customCss="margin: 2rem 0; max-width: 500px;">
            <StyledFlexColumn>
                <StyledEventDate isInThePast={isPastEvent(props.event)}>
                    {getDateText()}
                </StyledEventDate>
            </StyledFlexColumn>
            <StyledFlexColumn>
                <Heading tag="h4" variant="small">
                    {props.event?.listCardContent?.title || ''}
                </Heading>

                <StyledDate>
                    <Show 
                        fallback={dateAndLocationString()}
                        when={isOnDemandEvent(props.event)}
                    >
                        {localize('online', 'Online')}
                    </Show>
                </StyledDate>

                <StyledFlexRow justifyContent="space-between" alignItems="center">
                    <StyledReadMore href={isHiddenForPublic() ? useLocation().pathname : removeTrailingSlash(props.event?.url)}>
                        {localize('read-more', 'Read more')}
                    </StyledReadMore>
                </StyledFlexRow>
            </StyledFlexColumn>
        </Grid>
    );
};
