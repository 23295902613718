import { FormField } from './form-types';

const sharedFields: FormField[] = [
    {
        name: 'First Name',
        slug: 'firstname',
        required: true,
        autocomplete: 'given-name',
    },
    {
        name: 'Last Name',
        slug: 'lastname',
        required: true,
        autocomplete: 'family-name',
    },
    {
        name: 'Email',
        slug: 'email',
        type: 'email',
        required: true,
        autocomplete: 'new-email',
    },
];

export const medicalFields: FormField[] = [
    ...sharedFields,
    {
        name: 'City',
        slug: 'city',
        required: true,
    },
    {
        name: 'Country',
        slug: 'country',
        required: false,
        autocomplete: 'country',
    },
    {
        name: 'User Type',
        slug: 'user-type',
        required: true,
    },
];

export const careFields: FormField[] = [
    ...sharedFields,
    {
        name: 'Street name and number',
        slug: 'street',
        required: true,
    },
    {
        name: 'Post code',
        slug: 'zip',
        required: true,
        autoWidth: true,
    },
    {
        name: 'City',
        slug: 'city',
        required: true,
        autoWidth: true,
    },
    {
        name: 'Country',
        slug: 'country',
        required: true,
        autocomplete: 'country',
    },
    {
        name: 'Are you a caregiver',
        slug: 'is-caregiver',
        required: false,
    },

    // Patient info
    {
        name: 'Patient name',
        slug: 'patient-name',
        required: false,
    },
    {
        name: 'Patient street name and number',
        slug: 'patient-street',
        required: false,
    },
    {
        name: 'Patient postcode',
        slug: 'patient-postcode',
        required: false,
        autoWidth: true,
    },
    {
        name: 'Patient city',
        slug: 'patient-city',
        required: false,
        autoWidth: true,
    },
    {
        name: 'Patient email',
        slug: 'patient-email',
        type: 'email',
        required: false,
    },
];
