import { styled } from 'solid-styled-components';
import { fontFamilyAtosBold, theme } from '../../style/theme';

const { spacing,palette } = theme;

export const StyledProductWrapper = styled.div<{ adjustForHcpHeader: boolean }>`
    container-type: inline-size;
    @media print {
        display: none;
    }

    ${p => p.adjustForHcpHeader ? `
        ${theme.mediaQueries.desktopMin} {
            margin-top: 8rem;
        }
            
        margin-top: 6rem;
    ` : ''}
`;

export const StyledProductOuter = styled.div`
`;

export const StyledProduct = styled.div`
    position: relative;
    background-color: white;
    border-radius: 1.43rem;
    padding: ${spacing.xl} ${spacing.m} ${spacing.ms} ${spacing.m};

    ${theme.mediaQueries.mobileMax} {
        padding: ${spacing.s} ${spacing.xxs};
    }
`;

export const StyledMessage = styled.div`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: ${palette.darkGray};
    font-size: 2.29rem;
`;

export const StyledProductVideoContainer = styled.div`
    height: 21.43rem;
    display: flex;
    justify-content: center; 
    align-items: center;
`;

export const StyledMcRefContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: ${spacing.xs};
    margin-right: ${spacing.ms};
`;

export const StyledProductGeneralInfo = styled.div`
    padding-inline: ${spacing.xl};
    margin-top: 4rem;
    margin-bottom: 1rem;

    ${theme.mediaQueries.mobileMax} {
        padding-inline: ${spacing.s};
        margin-top: 3rem;
    }
`;

