import { useLocation } from '@solidjs/router';
import { gql } from 'graphql-request';
import { createEffect, Match, on, Show, Switch, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import DynamicModuleGenerator from '../../dynamic-module-generator';
import theme from '../../style/theme';
import { navigateToAnchor } from '../../tools/scroll-to-element';
import { NotFound } from '../404/404';
import { PageContextProvider } from './page-context-provider';
import { PageStyled } from './page.styles';

import { ErrorCatcher } from '../../tools/error-catcher';
import { PageCacheType } from '../../types/shared';
import { LockedContent } from '../../ui-components/layouts/locked-content';
import { SeoSettings } from '../seo-settings/seo-settings';
import { SidebarPage } from './sidebar-page';
import { ShareAndSave } from '../share-and-save/share-and-save';
import { HCPWarning } from '../hcp-warning/hcp-warning';
import { Text } from '../../ui-components/text/text';
import { Column } from '../../grid-system/column/column';
import { Section } from '../../grid-system/section/section';
import { Breadcrumb } from '../breadcrumb/breadcrumb';
import { Grid } from '../../grid-system/grid/grid';
import { removeTrailingSlash } from '../../tools/remove-trailing-slash';

export const GET_PAGE_QUERY = gql`
    query GetPage($key: String!) {
        page: resource(key: $key, includeAncestryMeta: true) {
            content
            ancestryMeta {
                postTitle
                shortTitle
            }
        }
    }
`;

export const PageContainer = () => {
    const { 
        appStore,
        setCacheType,
        createCachedResource,
        viewport,
        setStatusCode,
        userState,
        pageRegistration,
        siteInfo,
        headerSettings,
        careerPage,
    } = useContext(AppContext);

    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;
    const location = useLocation();

    const getCurrentPathName = () => {
        return removeTrailingSlash(location?.pathname?.toLowerCase());
    };

    const [pageContent] = createCachedResource(GET_PAGE_QUERY, () => ({ key: getCurrentPathName() }), true, { trackPath: getCurrentPathName });

    const loadWidgetScript = () => {
        const widgetScript = document.querySelector('script[src="https://widget.altrulabs.com/main.js"]');
        if (!widgetScript) {
            const script = document.createElement('script');
            script.src = 'https://widget.altrulabs.com/main.js';
            script.type = 'text/plain';
            script.setAttribute('data-cookieconsent', 'preferences');
            script.setAttribute('data-altru-widget-id', '12001361');
            script.setAttribute('data-altru-global-region', 'eu');
            document.head.appendChild(script);
        } else {
            const widgetContainer = document.querySelector('.altru-widget-container') as HTMLElement;
            if (widgetContainer) {
                widgetContainer.style.display = 'initial';
            }
        }
    };

    const hideWidget = () => {
        const widgetContainer = document.querySelector('.altru-widget-container') as HTMLElement;
        if (widgetContainer) {
            widgetContainer.style.display = 'none';
        }
    };

    const handleWidgetVisibility = (siteInfo: any, location: any, careerPage: any) => {
        if (siteInfo.siteSlug === 'international' && location.pathname === careerPage.url) {
            loadWidgetScript();
        } else {
            hideWidget();
        }
    };

    createEffect(() => {
        handleWidgetVisibility(siteInfo, location, careerPage);
    });

    const isOriginalTablet = () => viewport.width <= theme.breakpoints.ORIGINAL_TABLET;

    const isHcpPage = () => {
        if (checkHcp()) {
            setCacheType(PageCacheType.none);
            return true;
        }
        setCacheType(PageCacheType.defaultCache);
        return false;
    };

    const isHiddenForPublic = () => {
        const isHidden = isHcpPage() 
            && !pageContent()?.page?.content?.isVisibleForPublic 
            && !userState.user 
            && pageRegistration.url !== '';
            
        return isHidden;
    };

    const sidebarMenu = () => {
        if (pageContent()?.page?.content?.withSidebarMenu) {
            return pageContent()?.page?.content?.sidebarMenu;
        }
        return null;
    };

    const withSidebarMenu = () => {
        if (pageContent()?.page?.content?.withSidebarMenu && pageContent()?.page?.content?.sidebarMenu?.menu) {
            return true;
        }
        return false;
    };

    const checkHasHero = () => {
        const heroBlock = pageContent()?.page?.content?.blocks?.find((block: { blockName: string }) => 
            block.blockName === 'atos/hero' || block.blockName === 'atos/hero-campaign'
        );
        return Boolean(heroBlock);
    };

    const checkHcp = () => {
        // Campaign page supersedes HCP page (if we get more page types in the future, we need to rethink this)
        if (pageContent()?.page?.content?.isCampaignPage) {
            return false;
        }
        /**
         * "appStore.isHcp" behaves like a ratchet, once set to true, it can't be set to false,
         * unless the homepage is visited (which is a "patients" page, not a HCP page)
         */
        if (location.pathname === '/') {
            return false;
        }

        if (appStore.isHcp()) {
            return true;
        }
        
        return Boolean(pageContent()?.page?.content?.isHealthcareProfessional);
    };

    const checkCampaignPage = () => {
        return Boolean(pageContent()?.page?.content?.isCampaignPage);
    };

    const setHcp = () => {
        const isHcp = checkHcp();
        appStore.setHcp(isHcp);
        return null;
    };

    const setCampaignPage = () => {
        const isCampaignPage = checkCampaignPage();
        appStore.setCampaignPage(isCampaignPage);
        return null;
    };

    const setHasHero = () => {
        const hasHero = checkHasHero();
        appStore.setHasHero(hasHero);
        return null;
    };

    createEffect(
        on(
            () => location.hash,
            () => navigateToAnchor({
                id: location.hash.replace('#', ''),
                smallScreen: isMobile(),
                isCareSite: siteInfo.siteType === 'atos-care',
            })
        )
    );

    const found = () => {
        if (!pageContent()?.page?.content || pageContent?.loading) {            
            return false;
        }

        return isPagePostType();
    };

    const isPagePostType = () => {
        return pageContent()?.page?.content.type === 'page' || pageContent()?.page?.content.type === 'form';
    };

    if (!pageContent?.loading) {

        if (!pageContent()?.page || !pageContent()?.page?.content) {
            setStatusCode(404);
        }

        if (!isPagePostType()) {
            setStatusCode(404);
        }
    }

    const containsNonShareableBlockTypes = () => {
        const nonShareableTypes = ['atos/pdf-builder'];
        let hasBlock = false;

        if (pageContent().page?.content.blocks === undefined) {
            return false;
        }

        for (const block of pageContent().page.content.blocks) {
            if (block.blockName !== 'next24hr/section') {
                if (nonShareableTypes.includes(block.blockName)) {
                    hasBlock = true;
                }
                continue;
            }

            for (const column of block.blocks) {
                for (const nestedBlock of column.blocks) {
                    if (nonShareableTypes.includes(nestedBlock.blockName)) {
                        hasBlock = true;
                    }
                }
            }
        }

        return hasBlock;
    };

    setHcp();
    setHasHero();
    setCampaignPage();

    createEffect(() => {
        setHcp();
    });

    createEffect(() => {
        setCampaignPage();
    });

    createEffect(() => {
        setHasHero();
    });

    const showHcpHeader = () => headerSettings.activateHcpHeader && isHcpPage() && !isOriginalTablet();

    const contentWithoutHero = () => {
        const blocks = pageContent().page?.content?.blocks;
        if (!checkHasHero() || blocks?.length < 2) {
            return blocks;
        }
        const [, ...rest] = blocks;
        return rest;
    };

    return (
        <ErrorCatcher componentName="Page">
            <Show when={!pageContent.loading}>
                <PageContextProvider isHcpPage={isHcpPage()} hasHero={checkHasHero()} withSidebarMenu={withSidebarMenu()} sidebarMenu={sidebarMenu()}>
                    <HCPWarning isHcp={isHcpPage()} />
                    <PageStyled
                        hasHero={checkHasHero()}
                        isAtosCareSite={siteInfo.siteType === 'atos-care'}
                        adjustForHcpHeader={showHcpHeader()}
                    >
                        <SeoSettings seo={pageContent().page?.content?.seo} title={pageContent().page?.content?.post_title} />
                        <Switch>
                            <Match when={!found()}>
                                <NotFound />
                            </Match>
                            <Match when={withSidebarMenu()}>
                                <SidebarPage 
                                    data={pageContent().page}
                                    hasHero={checkHasHero()}
                                    isHcp={isHcpPage()}
                                    mcRef={pageContent().page?.content.mcRef}
                                />
                            </Match>
                            <Match when={pageContent()?.page?.content}>
                                <Show when={checkHasHero()}>
                                    <DynamicModuleGenerator content={[pageContent().page?.content.blocks[0]]} />
                                </Show>
                                <Show when={!isMobile() && !pageContent().page?.content?.disableBreadcrumbs && pageContent().page?.ancestryMeta?.length > 1}>
                                    <Grid templateShorthand={[12]} isLayoutGrid={true} customCss={`padding-top: ${checkHasHero() ? '3.5rem' : '1.5rem'};`}>
                                        <Breadcrumb pages={pageContent().page?.ancestryMeta} url={pageContent().page?.content?.permalink} />
                                    </Grid>
                                </Show>
                                <DynamicModuleGenerator
                                    content={checkHasHero() ? contentWithoutHero() : pageContent().page?.content.blocks}
                                    permalink={pageContent().page?.content.permalink}
                                    pageTitle={pageContent().page?.content.post_title}
                                />
                                <Show when={pageContent().page?.content?.mcRef}>
                                    <Section templateShorthand={[12]} widthType='bgFull' isLayoutSection={true}>
                                        <Column>
                                            <Text fontSize='smaller'>{pageContent().page?.content.mcRef}</Text>
                                        </Column>
                                    </Section>
                                </Show>
                            </Match>
                        </Switch>
                        <Show when={!containsNonShareableBlockTypes()}>
                            <ShareAndSave
                                type="page"
                                title={pageContent().page?.content.post_title}
                                permalink={pageContent().page?.content.permalink}
                                data={pageContent().page?.content}
                            />
                        </Show>
                        <Show when={isHiddenForPublic()}>
                            <LockedContent hasHero={checkHasHero()} />
                        </Show>
                    </PageStyled>
                </PageContextProvider>
            </Show>
        </ErrorCatcher>
    );
};
