import { TopCategory } from '../../../stores/events-store';
import { EventListCardData } from '../../event/event-types';

export const extractTopCategories = (events: EventListCardData[]): TopCategory[] => {
    const uniqueCategories = [] as TopCategory[];

    const processCategories = (categories?: TopCategory[]) => {
        if (!categories) {
            return;
        }

        categories?.forEach((category) => {
            if (uniqueCategories.find((item) => item.slug === category.slug)) {
                return;
            }

            uniqueCategories.push({
                slug: category.slug,
                name: category.name,
            });

            // second level categories
            if (category.children && category.children.length > 0) {
                processCategories(category.children);
            }
        });
    };

    events?.forEach((event) => processCategories(event?.categories));

    return Array.from(uniqueCategories);
};
