import { styled } from 'solid-styled-components';
import { fontFamilyAtosBold, fontFamilyAtosLight, palette, theme } from '../../../style/theme';
import { StyledHeaderProps, FrostedBackgroundProps } from './hcp-header.d';
import { frostedBackgroundBlurStyle } from '../shared';

const { header, grid } = theme;
const { desktop, mobile } = header;

const desktopHeight = {
    staticHeight: desktop.height.fixedHcp.value + desktop.height.fixedHcp.unit,
    floatingHeight: '100%',
};

const mobileHeight = mobile.height.value + mobile.height.unit;

export const textStyleCommon = `
    font-size: 1.29rem;
    letter-spacing: 0;
    line-height: normal;
    font-family: ${fontFamilyAtosLight};
    font-weight: 800;
    align-self: center;
`;

export const StyledHeader = styled.header<StyledHeaderProps>`
    overflow-x: clip;
    overflow-y: visible;
    container-type: inline-size;
    height: ${desktopHeight.staticHeight};
    position: sticky;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;

    ${(p) => (p.floatHeader ? 'height: unset;' : '')}

    ${theme.mediaQueries.tabletMax} {
        height: ${mobileHeight};
    }

    @media print {
        display: none;
    }
`;

const compressedLinkStyle = `
    padding-inline: 2rem;

    a,
    a:visited {
        font-size: 1.1rem;
        line-height: 1.5rem;
    }
`;

export const StyledLink = styled.div<{ isHcpHomeLink: boolean | null, floatHeader: boolean }>`
    position: relative;
    height: 100%;
    padding-inline: 3rem;
    display: flex;
    align-items: center;
    color: ${theme.palette.darkGray};
    text-decoration: none;
    font-size: 1.43rem;
    letter-spacing: 0;
    line-height: 1.71rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    text-align: center;
    background: transparent;
    transition: color 0.2s ease-in-out, padding-inline 0.2s ease-in-out, background 0.2s ease-in-out;

    a,
    a:visited {
        font-size: 1.29rem;
        letter-spacing: 0;
        line-height: 1.93rem;
        font-family: ${fontFamilyAtosBold};
        font-weight: 800;
        margin: 1.43rem 0;
        color: inherit;
        text-decoration: none;
        transition: font-size 0.2s ease-in-out, line-height 0.2s ease-in-out, color 0.2s ease-in-out;
    }
    a:hover {
        color: ${theme.palette.pink};
        text-decoration: underline;
    }

    ${(p) => p.floatHeader ? compressedLinkStyle : ''}

    ${(p) =>
        p.isHcpHomeLink
            ? `
                color: ${theme.palette.red} !important;
                text-decoration: underline;
                background: ${theme.palette.white};
                ${p.floatHeader ? 'background: transparent;' : ''}
            `
            : '' }

    ${theme.mediaQueries.desktopMax} {
        ${compressedLinkStyle}
    }
`;

export const InnerHeaderWrapper = styled.div`
    height: inherit;
    width: 100%;
`;

export const StyledBottomRow = styled.div<{ floatHeader: boolean }>`
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    width: 100%;
    transition: padding-bottom 0.2s ease-in-out;
    column-gap: 1rem;

    ${(p) => p.floatHeader ? 'padding-bottom: 0.5rem;' : '' }

    ${theme.mediaQueries.desktopMax} {
        padding-bottom: 0.5rem;
    }

    ${theme.mediaQueries.tabletMaxOriginal} {
        display: none;
    }
`;

export const StyledTest = styled.div<{ floatHeader: boolean }>`
    transition: opacity 0.2s ease-in-out;
    position: absolute;
    height: 100%;
    z-index: -1;
    left: -100%;
    right: -100%;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    ${p => p.floatHeader ? 'opacity: 0;' : 'opacity: 1;' }
`;


const compressedSideNavStyle = `
    padding-bottom: 0.5rem;
`;

export const StyledSideNav = styled.nav<{ floatHeader: boolean }>`
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    display: flex;
    column-gap: 1.5rem;
    transition: padding-top 0.2s ease-in-out, column-gap 0.2s ease-in-out, padding-bottom 0.2s ease-in-out;

    ${p => p.floatHeader
        ? `
            ${compressedSideNavStyle}
            padding-top: 0;
        ` : ''}

    ${theme.mediaQueries.desktopMax} {
        ${compressedSideNavStyle}
    }
`;

export const StyledTopRow = styled.div<{ floatHeader: boolean }>`
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    height: 7rem;
    transition: height 0.2s ease-in-out, background 0.2s ease-in-out;
    background: transparent;

    ${(p) => p.floatHeader ? `
        background: transparent;
        height: 5rem;
    ` : ''}

    ${theme.mediaQueries.tabletMax} {
        height: inherit;
    }
`;

export const FrostedBackground = styled.div<FrostedBackgroundProps>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: inherit;
    transition: background 0.2s ease-in-out;

    ${p => p.hasHero 
        ? `margin-inline: ${grid.standardUnit * 2 * -1}rem;` 
        : 'margin-inline: -100%;'}

    ${theme.mediaQueries.desktopMax} {
        margin-inline: ${grid.gapSizeTablet * -1}rem;
        border-radius: 0;
    }

    ${theme.mediaQueries.tabletMax} {
        margin-inline: ${grid.gapSizeMobile * -1}rem;
        border-radius: 0 0 ${grid.gapSizeMobile}rem ${grid.gapSizeMobile}rem;
    }

    ${(p) => p.floatHeader ? `
        background: transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
    ` : `background: ${theme.palette.beige};`}
`;

export const InnerUpperBackground = styled.div<{ floatHeader: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    transition: background 0.2s ease-in-out;

    ${(p) => p.floatHeader ? 'background: transparent;' : ''}
`;

export const InnerLowerBackground = styled.div<{ floatHeader: boolean, hasHero: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    &::after {
        content: '';
        left: ${grid.standardUnit * 2 * -1}rem;
        right: ${grid.standardUnit * 2 * -1}rem;
        position: absolute;
        height: 500%;
        top: -400%;
        z-index: -1;
        border-radius: 0 0 ${grid.standardUnit}rem ${grid.standardUnit}rem;
        transition: background 0.2s ease-in-out;

        ${(p) => p.floatHeader ? `
            ${frostedBackgroundBlurStyle}
            border: 1px solid transparent;
        ` : `background: ${theme.palette.white};`};

        ${theme.mediaQueries.desktopMax} {
            left: ${grid.gapSizeTablet * -1}rem;
            right: ${grid.gapSizeTablet * -1}rem;
            border-radius: 0;
        }

        ${theme.mediaQueries.tabletMax} {
            left: ${grid.gapSizeMobile * -1}rem;
            right: ${grid.gapSizeMobile * -1}rem;
            border-radius: 0 0 ${grid.gapSizeMobile}rem ${grid.gapSizeMobile}rem;
        }
    }

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        z-index: -1;
        top: 0;
        left: ${grid.standardUnit * 2 * -1}rem;
        right: ${grid.standardUnit * 2 * -1}rem;
        border-radius: 0 0 ${grid.standardUnit}rem ${grid.standardUnit}rem;
        transition: background 0.2s ease-in-out;
        background: rgba(0,0,0,0);

        /* ${(p) => p.floatHeader ? 'background: rgba(0,0,0,0);' : `background: ${theme.palette.white};`} */

        ${theme.mediaQueries.desktopMax} {
            left: ${grid.gapSizeTablet * -1}rem;
            right: ${grid.gapSizeTablet * -1}rem;
            border-radius: 0;
        }

        ${theme.mediaQueries.tabletMax} {
            left: ${grid.gapSizeMobile * -1}rem;
            right: ${grid.gapSizeMobile * -1}rem;
            border-radius: 0 0 ${grid.gapSizeMobile}rem ${grid.gapSizeMobile}rem;
        }
    }
`;

export const StyledTopRowMiddleColumn = styled.div<{ floatHeader: boolean }>`
    margin-left: 2rem;
    height: 100%;
    display: flex;
    transition: gap 0.2s ease-in-out;

    ${(p) => p.floatHeader ? 'gap: 0;' : 'gap: 2rem;'}

    ${theme.mediaQueries.desktopMax} {
        gap: 1rem;
    }

    ${theme.mediaQueries.tabletMaxOriginal} {
        display: none;
    }
`;

export const StyledTopRowRightColumn = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
`;

export const StyledMenuText = styled.div`
    margin-left: 1.14rem;

    ${theme.mediaQueries.desktopMax} {
        display: none;
    }
`;

export const StyledMenuButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 1.43rem;
    cursor: pointer;
`;

const iconPaddingDesktop = '1.5rem';
const iconPaddingTablet = '1.25rem';
const iconPaddingMobile = '0.9rem';

const compressedIconontainerStyle = `
    padding-block: 0.5rem;
`;

export const StyledIconContainer = styled.div<{ active: boolean; disablePadding?: boolean, floatHeader: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: ${p => p.disablePadding ? '0' : iconPaddingDesktop};
    padding-block: 1rem;
    cursor: pointer;
    transition: color 0.2s ease-in-out, padding-block 0.2s ease-in-out, font-size 0.2s ease-in-out;
    position: relative;
    white-space: nowrap;
    ${textStyleCommon}
    font-size: 1.15rem;
    color: ${theme.palette.darkGray};
    ${(p) => (p.active ? `color: ${theme.palette.pink};` : '')}

    ${p => p.floatHeader ? compressedIconontainerStyle : ''}

    ${(p) => p.active
        ? `
            svg {
                fill: ${palette.pink};
            }`
        : '' }

    &:hover {
        svg {
            fill: ${palette.pink};
        }
        h3,
        span {
            color: ${palette.pink};
        }
    }

    &:last-of-type {
        padding-right: 0;
    }

    ${theme.mediaQueries.desktopMax} {
        ${compressedIconontainerStyle}
    }

    ${theme.mediaQueries.tabletMax} {
        padding-inline: ${iconPaddingTablet};
    }

    ${theme.mediaQueries.mobileMax} {
        padding-inline: ${iconPaddingMobile};
    }
`;

const angleDownWidthLarge = 1.5;
const angleDownWidth = 1;

export const StyledAngleDownContainer = styled.div<{ floatHeader: boolean }>`
    position: absolute;
    left: calc(50% - ${angleDownWidthLarge / 2}rem);
    bottom: 0;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    & > svg {
        width: ${angleDownWidthLarge}rem;
        min-width: ${angleDownWidthLarge}rem;
    }

    ${p => p.floatHeader ? 'opacity: 0;' : ''}

    ${theme.mediaQueries.desktopMax} {
        left: calc(50% - ${angleDownWidth / 2}rem);
        bottom: 0.5rem;

        & > svg {
            width: ${angleDownWidth}rem;
            min-width: ${angleDownWidth}rem;
        }
    }
`;

export const StyledInnerGrid = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: inherit;
    z-index: 2;
`;

const compressedIconContainerInnerStyle = `
    top: 0;
`;

export const StyledIconContainerInner = styled.div<{ floatHeader: boolean }>`
    position: absolute;
    left: 0;
    top: 0.6rem;
    transition: top 0.2s ease-in-out;

    ${p => p.floatHeader ? compressedIconContainerInnerStyle : ''}

    ${theme.mediaQueries.desktopMax} {
        ${compressedIconContainerInnerStyle}
    }
`;
