import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

export const StyledPodcastWrapper = styled.div`
    margin-block: 2rem;
`;

export const StyledPodcast = styled.div``;

export const StyledPodcastNoCookiesMessage = styled.button`
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;
    background: ${theme.palette.white};
    border-radius: 1.43rem;
    border: 1px solid ${theme.palette.lightGray};
    transition: border 0.2s ease-in-out;

    & > .play-and-circle {
        transition: all 0.2s ease-in-out;

        svg {
            transition: all 0.2s ease-in-out;
        }
    }

    &:hover {
        border: 1px solid ${theme.palette.darkGray};

        & > .play-and-circle {
            background-color: rgba(0, 0, 0, 0.5);

            svg {
                min-width: 4rem;
                min-height: 4rem;
                width: 4rem;
                height: 4rem;
            }
        }
    }
`;
