import { createEffect, createSignal, onCleanup, onMount, Show, useContext } from 'solid-js';
import { ErrorCatcher } from '../../tools/error-catcher';
import { StyledPodcast, StyledPodcastNoCookiesMessage, StyledPodcastWrapper } from './podcast.style';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Heading } from '../../ui-components/heading/heading';
import { CookieModal } from '../../ui-components/cookie-modal/cookie-modal';
import { TabHandler } from '../menu/menu-desktop/tab-handling';
import { PlayAndCircle } from '../../ui-components/play-and-circle/play-and-circle';

type PodcastProps = {
    podcastProvider: string;
    id: string;
    podcastId: string;
};

export const Podcast = (props: PodcastProps) => {
    const [ elementId, setElementId ] = createSignal('');
    const [ cookiesAccepted, setCookiesAccepted ] = createSignal(false);
    const [ showModal, setShowModal ] = createSignal(false);

    // Accessibility/keyboard-controls related
    const [ firstTabbableElement, setFirstTabbableElement ] = createSignal<HTMLElement | undefined>();
    const [ lastTabbableElement, setLastTabbableElement ] = createSignal<HTMLElement | undefined>();
    
    const { localize } = useContext(AppContext);

    onMount(() => {
        checkConsent();

        // Listen for changes in consent
        window.addEventListener('CookiebotOnAccept', () => {
            checkConsent();
        });

        onCleanup(() => {
            window.removeEventListener('CookiebotOnAccept', checkConsent);
        });
    });

    createEffect(() => {
        if (!cookiesAccepted()) {
            return;
        }

        if (props.podcastProvider !== 'buzzsprout' || !props.podcastId || !props.id) {
            return;
        }

        if (!document) {
            return;
        }

        const elementId = Math.random().toString(36).substring(7);
        setElementId(elementId);

        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.dataset.cookieconsent = 'marketing';
        scriptTag.src = `https://www.buzzsprout.com/${props.podcastId}/${props.id}.js?container_id=buzzsprout-${elementId}&player=small`;
        document.head.appendChild(scriptTag);
    });

    const checkConsent = () => {
        if (window?.Cookiebot?.consent?.marketing) {
            setCookiesAccepted(true);
        }
    };

    return (
        <ErrorCatcher componentName="Podcast">
            <StyledPodcastWrapper>
                <Show when={!cookiesAccepted()}>
                    <StyledPodcastNoCookiesMessage onClick={() => setShowModal(true)}>
                        <PlayAndCircle />
                        <Heading tag="span" variant='smallRed'>{localize('play-podcast-episode', 'Play podcast episode')}</Heading>
                    </StyledPodcastNoCookiesMessage>
                </Show>

                <Show when={cookiesAccepted()}>
                    <StyledPodcast>
                        <Show when={props.podcastProvider === 'buzzsprout' && props.id}>
                            <div>
                                <div id={`buzzsprout-${elementId()}`} />
                            </div>
                        </Show>

                        <Show when={props.podcastProvider === 'captivate' && props.id}>
                            <div style="width: 100%; height: 200px; margin-bottom: 20px; border-radius: 6px; overflow: hidden;">
                                <iframe
                                    style="width: 100%; height: 200px;"
                                    src={`https://player.captivate.fm/episode/${props.id}`}
                                />
                            </div>
                        </Show>
                    </StyledPodcast>
                </Show>
            </StyledPodcastWrapper>

            <Show when={showModal()}>
                <TabHandler refs={{
                    firstTabbableElement,
                    lastTabbableElement,
                }}>
                    <CookieModal
                        close={() => setShowModal(false)}
                        setFirstTabbableElement={(el: HTMLElement) => setFirstTabbableElement(el)}
                        setLastTabbableElement={(el: HTMLElement) => setLastTabbableElement(el)}
                    />
                </TabHandler>
            </Show>


        </ErrorCatcher>
    );
};

Podcast.parseProps = (atts: any) => {
    return {
        podcastProvider: atts.podcastProvider,
        id: atts.id,
        podcastId: atts.podcastId,
    };
};

