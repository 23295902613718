import { EventListCardData } from '../../event/event-types';
import { isOnDemandEvent } from './is-on-demand-event';

export const isPastEvent = (event: EventListCardData) => {
    if (isOnDemandEvent(event)) {
        return false;
    }
    
    const currentDateTime = new Date();
    const eventDateTime = new Date(`
        ${event?.date} ${event?.eventTime}
    `);

    // Events older than 30 minutes should not be shown
    eventDateTime.setMinutes(eventDateTime.getMinutes() + 30);

    return eventDateTime <= currentDateTime;
};
