import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../style/theme';

export const StyledRegistrationForm = styled.form`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

type StyledFormInputProps = {
    hasErrors?: boolean;
    autoWidth?: boolean;
};

export const StyledFormInput = styled.input<StyledFormInputProps>`
    font-size: ${theme.fontSize.medium};
    font-family: ${fontFamilyAtosRegular};
    border-radius: 5px;
    padding: 1rem;
    border: ${p => p.hasErrors ? `1px solid ${theme.colors.text.pink}` : '1px solid #707070'};
    ${p => p.autoWidth 
        ? `
            width: auto;
            flex-grow: 1;
        ` : `
            width: 100%;
        `};
    `;

export const StyledPatientInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;

export const StyledRegistrationSplash = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${theme.palette.red};
`;

export const StyledFormSelect = styled.select<StyledFormSelectProps>`
    font-size: ${theme.fontSize.medium};
    font-family: ${fontFamilyAtosRegular};
    border-radius: 5px;
    padding: 1rem;
    background-color: white;
    color: ${p => p.value === '' ? '#707070' : 'black'};
    border: ${p => p.hasErrors ? `1px solid ${theme.colors.text.pink}` : '1px solid #707070'};
    width: 100%;
    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
    option {
        color: black;
    }

    svg {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
`;
type StyledFormSelectProps = {
    value: string;
    hasErrors?: boolean;
};
export const StyledFormSelectWrapper = styled.div`
    position: relative;
`;

export const StyledIconWrapper = styled.div`
    position: absolute;
    right: 1.8rem;
    top: 0;
    bottom: 0;
    height: 10px;
    pointer-events: none;
    margin: auto;
`;

export const StyledCheckboxGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
`;
