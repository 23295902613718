import { Match, mergeProps, Show, Switch, useContext } from 'solid-js';
import { StyledArrowLink, StyledLinkAsButton, StyledArrowLinkLabel, StyledArrowLinkAsButton, StyledAnchorLink, StyledLink, StyledArrowIcon, StyledPlainLinkLabel } from './link.styles';
import { CustomLinkProps } from './link-types';
import { ArrowLeftIcon } from '../../components/icons-library/arrow-left';
import theme from '../../style/theme';
import { ArrowUpIcon } from '../../components/icons-library/arrow-up';
import { ArrowRightIcon } from '../../components/icons-library/arrow-right';
import { ArrowDownIcon } from '../../components/icons-library/arrow-down';
import urlMatches from '../../tools/url-match';
import { navigateToAnchor } from '../../tools/scroll-to-element';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';
import { removeTrailingSlash } from '../../tools/remove-trailing-slash';
import { isExternalLink } from '../../tools/check-is-external-url';
import removeAmpersand from '../../tools/remove-ampersand';
import { isDownloadLink } from '../../tools/check-is-download-link';

const defaultProps = {
    opensInNewTab: false,
    darkMode: false,
    noBlockSpacing: false,
};

const iconSizeMap = {
    small: {
        height: 1.29,
        width: 1.57,
    },
    medium: {
        height: 1.3,
        width: 1.7,
    },
    large: {
        height: 2.14,
        width: 2.14,
    },
};

export const Link = (componentProps: CustomLinkProps) => {
    const { viewport, siteInfo, } = useContext(AppContext);

    const props = mergeProps(defaultProps, componentProps);
    
    let url = removeTrailingSlash(props.url);
    url = removeAmpersand(url);

    if ((!props.asButton && !url) || !props.label) {
        return <></>;
    }

    const isSmallScreen = () => viewport.width <= theme.breakpoints.TABLET;

    const opensInNewTab = () => props.opensInNewTab || isExternalLink(url) || isDownloadLink(url);

    const anchorLink = () => {
        // In case the url comes from an ACF field, such as the "register now" link on events, we need to check it rather than rely on the props
        return props.anchorLink || (url?.slice(0,1) === '#' ? url.replace('#', '') : '');
    };

    const arrowLinkContent = () => {
        return (
            <>
                <Show when={props.arrowPosition === 'right'}>
                    <StyledArrowLinkLabel breakWords={props.breakWords} arrowPosition={'right'}>
                        {removeAmpersand(props.label)}
                    </StyledArrowLinkLabel>
                </Show>
                <StyledArrowIcon display={Boolean(props.arrowDirection)}>
                    <Switch>
                        <Match when={props.arrowDirection == 'up'}>
                            <ArrowUpIcon fill={props.arrowColor || theme.palette.gray} {...(props.size ? iconSizeMap[props.size] : iconSizeMap.medium)} />
                        </Match>
                        <Match when={props.arrowDirection == 'right'}>
                            <ArrowRightIcon fill={props.arrowColor || theme.palette.gray} {...(props.size ? iconSizeMap[props.size] : iconSizeMap.medium)} />
                        </Match>
                        <Match when={props.arrowDirection == 'down'}>
                            <ArrowDownIcon fill={props.arrowColor || theme.palette.gray} {...(props.size ? iconSizeMap[props.size] : iconSizeMap.medium)} />
                        </Match>
                        <Match when={props.arrowDirection == 'left'}>
                            <ArrowLeftIcon fill={props.arrowColor || theme.palette.gray} {...(props.size ? iconSizeMap[props.size] : iconSizeMap.medium)} />
                        </Match>
                    </Switch>
                </StyledArrowIcon>
                <Show when={props.arrowPosition === 'left'}>
                    <StyledArrowLinkLabel breakWords={props.breakWords} arrowPosition={'left'}>
                        {removeAmpersand(props.label)}
                    </StyledArrowLinkLabel>
                </Show>
                <Show when={!props.arrowPosition}>
                    <StyledPlainLinkLabel breakWords={props.breakWords}>
                        {removeAmpersand(props.label)}
                    </StyledPlainLinkLabel>
                </Show>
            </>
        );
    };

    return (
        <ErrorCatcher componentName='Link'>
            <Show when={!props.asButton && !props.plainStyle && url}>
                <Show when={anchorLink()}>
                    <StyledAnchorLink
                        href={url}
                        darkMode={props.darkMode}
                        size={props.size}
                        active={urlMatches(url)}
                        lineLimit={props.lineLimit}
                        onClick={(e: any) => navigateToAnchor({
                            event: e,
                            id: anchorLink(),
                            smallScreen: isSmallScreen(),
                            isCareSite: siteInfo.siteType === 'atos-care',
                        })}
                        ref={(el: HTMLElement) => props.element && props.element(el)}
                    >
                        {removeAmpersand(props.label)}
                    </StyledAnchorLink>
                </Show>
                <Show when={!anchorLink()}>
                    <StyledLink
                        href={url}
                        darkMode={props.darkMode}
                        active={urlMatches(url)}
                        noBlockSpacing={props.noBlockSpacing}
                        size={props.size}
                        onClick={props.onClick}
                        lineLimit={props.lineLimit}
                        ref={(el: HTMLElement) => props.element && props.element(el)}
                        {...(opensInNewTab()
                            ? {
                                target: '_blank',
                                rel: 'noopener noreferrer',
                            }
                            : {})}
                    >
                        {removeAmpersand(props.label)}
                    </StyledLink>
                </Show>
            </Show>
            <Show when={props.plainStyle && url}>
                <Show when={anchorLink()}>
                    <StyledArrowLinkAsButton
                        darkMode={props.darkMode}
                        labelColor={props.labelColor}
                        size={props.size}
                        bold={props.bold}
                        active={urlMatches(url)}
                        lineLimit={props.lineLimit}
                        onClick={(e: any) => navigateToAnchor({
                            event: e,
                            id: anchorLink(),
                            smallScreen: isSmallScreen(),
                            isCareSite: siteInfo.siteType === 'atos-care',
                        })}
                        ref={(el: HTMLElement) => props.element && props.element(el)}
                    >
                        {arrowLinkContent()}
                    </StyledArrowLinkAsButton>
                </Show>
                <Show when={!anchorLink()}>
                    <StyledArrowLink 
                        href={url} 
                        darkMode={props.darkMode} 
                        labelColor={props.labelColor} 
                        size={props.size}
                        bold={props.bold}
                        active={urlMatches(url)}
                        lineLimit={props.lineLimit}
                        ref={(el: HTMLElement) => props.element && props.element(el)}
                        {...(opensInNewTab()
                            ? {
                                target: '_blank',
                                rel: 'noopener noreferrer',
                            }
                            : {})}
                    >
                        {arrowLinkContent()}
                    </StyledArrowLink>
                </Show>
            </Show>
            <Show when={props.asButton}>
                <StyledLinkAsButton 
                    // href={url}
                    darkMode={props.darkMode}
                    asButton={true}
                    onClick={props.onClick}
                    active={urlMatches(url)}
                    size={props.size}
                    lineLimit={props.lineLimit}
                    noBlockSpacing={props.noBlockSpacing}
                    ref={(el: HTMLElement) => props.element && props.element(el)}
                >
                    {removeAmpersand(props.label)}
                </StyledLinkAsButton>
            </Show>
        </ErrorCatcher>
    );
};

Link.parseProps = (atts: any) => {    
    let anchorLink = '';
    // anchor tags start with #, so we can use that to identify them
    if (atts?.url?.slice(0,1) === '#') {
        anchorLink = atts.url.replace('#', '');        
    }

    return {
        ...atts,
        anchorLink,
    };
};
