import { useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import { AppContext } from '../app-context-provider/app-context-provider';
import { ProductCategory, ProductListCardData } from '../components/product/product-types';
import { AreasOfCare, DataObject, NestedCategory } from '../components/products/products-types';
import theme from '../style/theme';

const { viewport } = useContext(AppContext);

export type ProductsStoreMethods = {
    isMobile: boolean;
    areaOfCare: ProductCategory | undefined;
    availableAreasOfCare: AreasOfCare;
    availableProductCategories: NestedCategory[];
    selectedProductCategories: NestedCategory[];
    availableTypes: NestedCategory[];
    selectedTypes: NestedCategory[];
    results: ProductListCardData[];
    labels: {
        heading: string;
        topCategoryHeading: string;
        firstNestedCategoryHeading: string;
        secondNestedCategoryHeading: string;
    };
};

export const [productsStore, setProductsStore] = createStore<ProductsStoreMethods>({
    isMobile: viewport.width <= theme.breakpoints.MOBILE,
    areaOfCare: undefined,
    availableAreasOfCare: {},
    availableProductCategories: [],
    selectedProductCategories: [],
    availableTypes: [],
    selectedTypes: [],
    results: [],
    labels: {
        heading: '',
        topCategoryHeading: '',
        firstNestedCategoryHeading: '',
        secondNestedCategoryHeading: '',
    },
});

export const setIsMobile = (isMobile: boolean) => {
    setProductsStore('isMobile', () => isMobile);
};

/**
 * Areas of Care
 */
export const setAreaOfCare = (area: ProductCategory | undefined) => setProductsStore(() => ({ areaOfCare: area }));
export const setAvailableAreasOfCare = (areas: AreasOfCare) => setProductsStore(() => ({ availableAreasOfCare: areas }));

/**
 * Product Categories
 */
export const setAvailableProductCategories = (categories: NestedCategory[]) => setProductsStore(() => ({ availableProductCategories: categories }));
export const setSelectedProductCategories = (categories: NestedCategory[]) => setProductsStore(() => ({ selectedProductCategories: categories }));
export const addSelectedProductCategory = (category: NestedCategory) => setProductsStore('selectedProductCategories', (prev: NestedCategory[]) => [...prev, category]);
export const removeSelectedProductCategory = (category: NestedCategory) =>
    setProductsStore('selectedProductCategories', (prev: NestedCategory[]) => {
        let indexPosition = -1;
        productsStore?.selectedProductCategories?.find((cat: NestedCategory, index: number) => {
            indexPosition = index;
            return cat._data.slug === category._data.slug;
        });

        const updatedCategories = [...prev];
        updatedCategories.splice(indexPosition, 1);

        return updatedCategories;
    });

/**
 * Types
 */
export const setAvailableTypes = (types: NestedCategory[]) => setProductsStore(() => ({ availableTypes: types }));
export const setSelectedTypes = (types: NestedCategory[]) => setProductsStore(() => ({ selectedTypes: types }));
export const addSelectedType = (type: NestedCategory) => setProductsStore('selectedTypes', (prev: NestedCategory[]) => [...prev, type]);
export const removeSelectedType = (type: { [key: string]: any } ) =>
    setProductsStore('selectedTypes', (prev: NestedCategory[]) => {
        let indexPosition = -1;
        productsStore.selectedTypes?.find((s: NestedCategory, index: number) => {
            indexPosition = index;
            return s._data.slug === type._data.slug;
        });

        const updatedTypes = [...prev];
        updatedTypes.splice(indexPosition, 1);
        return updatedTypes;
    });

export const changeAreaOfCare = (area: DataObject) => {
    setSelectedProductCategories([]);
    setSelectedTypes([]);
    setAreaOfCare(area._data);

    const categories: DataObject[] = Object.keys(area)
        .filter((k) => k !== '_data')
        .sort((a, b) => a.localeCompare(b))
        .map((key) => area[key])
        .sort(orderSorter);

    setAvailableProductCategories(categories);
    setAvailableTypes([]);
};

export const orderSorter = (a: DataObject, b: DataObject) => {
    const categoryA = a?._data;
    const categoryB = b?._data;

    const orderRankA = (categoryA?.categoryOrderRanking && parseInt(categoryA.categoryOrderRanking, 10)) || 10;
    const orderRankB = (categoryB?.categoryOrderRanking && parseInt(categoryB.categoryOrderRanking, 10)) || 10;

    if (orderRankA !== orderRankB) {
        return orderRankA - orderRankB;
    }

    return 0;
};

export const changeSelectedProductCategory = (category: NestedCategory) => {    
    const alreadyAdded = productsStore?.selectedProductCategories?.find((cat: NestedCategory) => {
        return cat._data.slug === category._data.slug;
    });

    if (alreadyAdded) {
        const typesToUnselect = Object.keys(alreadyAdded).filter((c) => c !== '_data');
        typesToUnselect.forEach((type) => removeSelectedType(alreadyAdded[type]));

        removeSelectedProductCategory(alreadyAdded);
    } else {
        addSelectedProductCategory(category);
    }

    const types: NestedCategory[] = [];
    productsStore?.selectedProductCategories.forEach((category: DataObject) => {
        Object.keys(category)
            .filter((c) => c !== '_data')
            .sort((a, b) => a.localeCompare(b))
            .map((key) => category[key])
            .sort(orderSorter)
            .forEach((category) => types.push(category));
    });

    setAvailableTypes(types);
};

export const changeSelectedType = (type: NestedCategory) => {
    const alreadyAdded = productsStore.selectedTypes?.find((s: NestedCategory) => {
        return s._data.slug === type._data.slug;
    });

    if (alreadyAdded) {
        removeSelectedType(alreadyAdded);
    } else {
        addSelectedType(type);
    }
};

export const clearFilters = (e?: any) => {
    if (e) {
        e.preventDefault();
    }
    
    setSelectedProductCategories([]);
    setSelectedTypes([]);
    setAreaOfCare(undefined);
    setAvailableProductCategories([]);
    setAvailableTypes([]);
};
