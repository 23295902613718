import { styled } from 'solid-styled-components';
import { PlayIcon } from '../../components/icons-library/play';
import theme from '../../style/theme';
import { useContext, Show } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';

const StyledPlayAndCircleWrapper = styled.div`
    flex-shrink: 0;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 8rem;

    ${theme.mediaQueries.mobileMax} {
        width: 6rem;
        height: 6rem;
    }
`;

const StyledPlayIconAligner = styled.div`
    transform: translateX(10%);
`;

export const PlayAndCircle = () => {
    const { viewport } = useContext(AppContext);

    return (

        <StyledPlayAndCircleWrapper class="play-and-circle">
            <StyledPlayIconAligner>
                <Show
                    fallback={<PlayIcon width={4.5} height={4.5} fill='white' />}
                    when={viewport.width <= theme.breakpoints.MOBILE}>
                    <PlayIcon width={3.5} height={3.5} fill='white' />
                </Show>
            </StyledPlayIconAligner>
        </StyledPlayAndCircleWrapper>

    );
};

