import { createEffect, createSignal, on, Show, onCleanup, onMount, /* useContext, */ Match, Switch } from 'solid-js';
import { StyledVideoContainer } from './video-iframe-container.style';
import { getYoutubeVideoId, getVimeoUrl } from '../../components/video-embed/helpers';
import { VideoImageBeforeCookiesAccepted } from '../../components/video-embed/video-image-before-cookie';
import { CookieModal } from '../cookie-modal/cookie-modal';
import { TabHandler } from '../../components/menu/menu-desktop/tab-handling';

type VideoIframeContainerProps = {
    videoLink?: string;
};


export const VideoIframeContainer = (props: VideoIframeContainerProps) => {
    const [ isLoading, setIsLoading ] = createSignal(false);
    const [showModal, setShowModal] = createSignal(false);
    const [autoplayVideo, setAutoplayVideo] = createSignal(false);
    const [videoCookiesAccepted, setVideoCookiesAccepted] = createSignal(false);

    // Accessibility/keyboard-controls related
    const [ firstTabbableElement, setFirstTabbableElement ] = createSignal<HTMLElement | undefined>();
    const [ lastTabbableElement, setLastTabbableElement ] = createSignal<HTMLElement | undefined>();

    createEffect(() => {
        setIsLoading(true);
    });

    createEffect(
        on(
            () => props.videoLink,
            () => {                
                setIsLoading(false);
                setTimeout(() => {
                    setIsLoading(true);
                }, 10);
            }
        )
    );

    onMount(() => {
        checkConsent();

        // Listen for changes in consent
        window.addEventListener('CookiebotOnAccept', () => {
            checkConsent();
        });

        onCleanup(() => {
            window.removeEventListener('CookiebotOnAccept', checkConsent);
        });
    });

    const checkConsent = () => {
        if (window?.Cookiebot?.consent?.marketing) {
            setVideoCookiesAccepted(true);
        }
    };

    const handleThumbnailClick = () => {
        setAutoplayVideo(true);
        setShowModal(true);
    };

    return (
        <Show when={props.videoLink}>
            <StyledVideoContainer
                isLoading={isLoading()}
                onClick={() => !videoCookiesAccepted() ? handleThumbnailClick() : null}
                videoCookiesAccepted={videoCookiesAccepted()}
            >
                <Switch>
                    <Match when={videoCookiesAccepted()}>
                        <Show when={props.videoLink?.includes('youtu')}>
                            <iframe
                                src={`https://www.youtube-nocookie.com/embed/${getYoutubeVideoId(props.videoLink!)}?autoplay=${autoplayVideo() ? 1 : 0}&mute=${autoplayVideo() ? 1 : 0}`}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </Show>
                        <Show when={props.videoLink?.includes('vimeo')}>
                            <iframe
                                src={getVimeoUrl(props.videoLink!)}
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </Show>
                    </Match>
                    <Match when={!videoCookiesAccepted()}>
                        <VideoImageBeforeCookiesAccepted 
                            videoUrl={props.videoLink!}
                            withoutBorderRadius={true}
                        />
                    </Match>
                </Switch>

                <Show when={showModal()}>
                    <TabHandler refs={{
                        firstTabbableElement,
                        lastTabbableElement,
                    }}>
                        <CookieModal
                            close={() => setShowModal(false)}
                            setFirstTabbableElement={(el: HTMLElement) => setFirstTabbableElement(el)}
                            setLastTabbableElement={(el: HTMLElement) => setLastTabbableElement(el)}
                        />
                    </TabHandler>
                </Show>

            </StyledVideoContainer>
        </Show>
    );
};

