import { gql } from 'graphql-request';
import { useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { formStore } from './registration-form-store';
import { RegistrationForm, UserServiceResponse } from './registration-form';
import { medicalFields } from './fields';
import { Button } from '../../ui-components/button/button';
import { Heading } from '../../ui-components/heading/heading';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';

export const createUserMutation = gql`
    mutation createUser(
        $email: String!
        $firstName: String
        $lastName: String
        $country: String
        $city: String
        $userType: String!
        $institution: String
        $password: String!
        $clientOrigin: String!
        $verifyAccountUrl: String!
        $referer: String!
        $siteCountry: String
        $captchaToken: String!
        $company: String
    ) {
        createUser(
            email: $email
            firstName: $firstName
            lastName: $lastName
            country: $country
            city: $city
            userType: $userType
            institution: $institution
            password: $password
            clientOrigin: $clientOrigin
            verifyAccountUrl: $verifyAccountUrl
            referer: $referer
            siteCountry: $siteCountry
            captchaToken: $captchaToken
            company: $company
        ) {
            status
            message
        }
    }
`;

    
export const MedicalRegistrationWrapper = () => {
    const { appStore, localize, pageVerifyAccountUrl, siteInfo, graphQlClient } = useContext(AppContext);
    const { openLoginMenu } = appStore;

    const createUser = async (captchaToken: string): Promise<UserServiceResponse> => {
        const createUserArgs = {
            verifyAccountUrl: pageVerifyAccountUrl,
            siteCountry: siteInfo.country || '',
            clientOrigin: window.location.origin,
            referer: window.location.href,
            email: formStore.fields.email,
            firstName: formStore.fields.firstname,
            lastName: formStore.fields.lastname,
            country: formStore.fields.country,
            city: formStore.fields.city,
            password: formStore.fields.password,
            userType: formStore.fields[ 'user-type' ],
            company: formStore.fields.company,
            captchaToken,
        };

        return await graphQlClient(createUserMutation, createUserArgs, false);
    };

    const NavigationComponents = () => {
        return (
            <StyledFlexRow gap='2rem' flexWrap='wrap' justifyContent='center'>
                <Button label={localize('login', 'Login')} onClick={() => openLoginMenu()} />
            </StyledFlexRow>
        );
    };

    const SuccessMessageComponents = () => {
        const successRegisteringUser = localize('registration-successful', 'Successfully created user account with email');
        const message = localize('registration-successful', `${successRegisteringUser} ${formStore.fields.email}. ${localize('check-email-cta', 'Please check your email for a verification link') + '.'}`);

        return (
            <Heading tag='h4' variant='smallGray' noBlockSpacing>
                {message}
            </Heading>
        );
    };

    return (
        <RegistrationForm
            createUser={(captchaToken) => createUser(captchaToken)}
            fields={medicalFields}
            navigationComponents={<NavigationComponents/>}
            successMessageComponents={<SuccessMessageComponents/>}
        />
    );
};
