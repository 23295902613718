import { EventListCardData, EventListDataContainer } from '../../event/event-types';

export const mapEventMetaToListCardData = (event: EventListDataContainer): EventListCardData => {
    // We map the new meta data to the pre-existing data structure for backwards compatibility

    const mappedData: EventListCardData = {
        url: event?.meta?.extra?.url || '',
        permalink: event.key,
        categories: event?.meta?.categories,
        ...event?.meta?.extra,
    };

    return mappedData;
};


