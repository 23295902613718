import { Link } from '@solidjs/router';
import { createSignal, Match, onMount, Show, Switch, useContext, onCleanup } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../../tools/error-catcher';
import { LockedContent } from '../../../ui-components/layouts/locked-content';
import { getBackgroundImageSrcData, ImageSrcData } from '../../image-component/image-component';
import { getVimeoUrl, getYoutubeVideoId } from '../../video-embed/helpers';
import { VideoPostCardProps } from './video-post-card-types';
import { StyledCardContent, StyledCardTitle, StyledImage, StyledVideoPostCard, StyledProductVideo, StyledVideoCardWrapper } from './video-post-card.styles';
import { MyContentHandler } from '../../my-content-handler/my-content-handler';
import { removeTrailingSlash } from '../../../tools/remove-trailing-slash';
import removeAmpersand from '../../../tools/remove-ampersand';
import { styled } from 'solid-styled-components';
import theme from '../../../style/theme';
import { VideoImageBeforeCookiesAccepted } from '../../video-embed/video-image-before-cookie';
import { CookieModal } from '../../../ui-components/cookie-modal/cookie-modal';
import { TabHandler } from '../../menu/menu-desktop/tab-handling';

declare global {
    interface Window {
        Cookiebot: any;
    }
}

export const StyledModal = styled.div`
    text-align: left;
    padding: 0 6rem;

    ${theme.mediaQueries.tabletMax} {
        padding: 0;
    }
`;

export const StyledPlayImage = styled.img`
    width: 100%;
    background-color: ${theme.palette.lightGray};
`;


export const VideoPostCard = (props: VideoPostCardProps) => {
    const { imagesServiceUrl, supportedImageFormats, userState, pageRegistration, videosEntityName } = useContext(AppContext);
    const [backgroundImageSrcData, setBackgroundImageSrcData] = createSignal<ImageSrcData>();
    const [showModal, setShowModal] = createSignal(false);
    const [autoplayVideo, setAutoplayVideo] = createSignal(false);
    const [videoCookiesAccepted, setVideoCookiesAccepted] = createSignal(false);

    // Accessibility/keyboard-controls related
    const [ firstTabbableElement, setFirstTabbableElement ] = createSignal<HTMLElement | undefined>();
    const [ lastTabbableElement, setLastTabbableElement ] = createSignal<HTMLElement | undefined>();    

    onMount(() => {
        if (props?.image?.url) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.image.url,
                width: 500,
                height: 'AUTO',
            });

            setBackgroundImageSrcData(backgroundImage);
        }

        checkConsent();

        // Listen for changes in consent
        window.addEventListener('CookiebotOnAccept', () => {
            checkConsent();
        });

        onCleanup(() => {
            window.removeEventListener('CookiebotOnAccept', checkConsent);
        });
    });

    const checkConsent = () => {
        if (window?.Cookiebot?.consent?.marketing) {
            setVideoCookiesAccepted(true);
        }
    };


    const handleThumbnailClick = () => {
        if (!props.ableToPlay) {
            return;
        }
        setAutoplayVideo(true);
        setShowModal(true);
    };

    if (!props.videoUrl) {
        return null;
    }

    const isHiddenForPublic = () => {
        return props.isHealthcareProfessional 
            && !props.isVisibleForPublic 
            && !userState.user 
            && pageRegistration.url !== '';
    };

    const postTypeSlug = '/video-item/';
    const permalinkWithEntityName = videosEntityName && props.permalink && props.permalink?.replace(postTypeSlug, `/${videosEntityName}/`);

    const VideoCard = () => (
        <>
            <StyledProductVideo 
                ableToPlay={props.ableToPlay}
                videoCookiesAccepted={videoCookiesAccepted()}
                onClick={() => !videoCookiesAccepted() ? handleThumbnailClick() : null}
            >
                <Switch>
                    <Match when={videoCookiesAccepted()}>
                        <Switch>
                            <Match when={props?.image?.url}>
                                <StyledImage 
                                    class="styledImage" 
                                    src={backgroundImageSrcData()?.original}
                                    title={props?.image?.name || ''}
                                    alt={props?.image?.alt || ''}
                                />
                            </Match>
                            <Match when={props?.videoUrl?.includes('youtu')}>
                                <iframe
                                    src={`https://www.youtube-nocookie.com/embed/${getYoutubeVideoId(props.videoUrl)}?autoplay=${autoplayVideo() ? 1 : 0}&mute=${autoplayVideo() ? 1 : 0}`}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </Match>
                            <Match when={props?.videoUrl?.includes('vimeo')}>
                                <iframe
                                    src={getVimeoUrl(props.videoUrl, autoplayVideo())}
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </Match>
                        </Switch>
                    </Match>
                    <Match when={!videoCookiesAccepted()}>
                        <VideoImageBeforeCookiesAccepted
                            videoUrl={props.videoUrl}
                            image={props?.image}
                            bgImage={backgroundImageSrcData()?.original}
                        />
                    </Match>
                </Switch>
            </StyledProductVideo>
            <StyledCardContent>
                <StyledCardTitle insideSlider={props.insideSlider}>
                    {removeAmpersand(props.title)}
                </StyledCardTitle>
                <Show when={!props.hideMyContentStar}>
                    <MyContentHandler
                        action='add'
                        title={props.title}
                        permalink={props.permalink}
                        type={'video'}
                        onlyIcon={true}
                    />
                </Show>
            </StyledCardContent>
        </>
    );


    return (
        <ErrorCatcher componentName="Video post card">
            <StyledVideoCardWrapper>
                <StyledVideoPostCard inGrouping={props.inGrouping}>
                    <Show when={props.ableToPlay} fallback={
                        <Link href={permalinkWithEntityName ? removeTrailingSlash(permalinkWithEntityName) : ''}>
                            <VideoCard />
                        </Link>
                    }>
                        <VideoCard />
                    </Show>

                    <Show when={isHiddenForPublic()}>
                        <LockedContent isColumn={true} />
                    </Show>
                </StyledVideoPostCard>

                <MyContentHandler action='remove' permalink={props.permalink} />

                <Show when={showModal()}>
                    <TabHandler refs={{
                        firstTabbableElement,
                        lastTabbableElement,
                    }}>
                        <CookieModal
                            close={() => setShowModal(false)}
                            setFirstTabbableElement={(el: HTMLElement) => setFirstTabbableElement(el)}
                            setLastTabbableElement={(el: HTMLElement) => setLastTabbableElement(el)}
                        />
                    </TabHandler>
                </Show>

            </StyledVideoCardWrapper>
        </ErrorCatcher>
    );
};
