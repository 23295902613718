import { Show, useContext } from 'solid-js';
import {
    productsStore,
} from '../../../../../stores/products-store';
import { NestedCategory } from '../../../../products/products-types';
import { ProductListCardData } from '../../../../product/product-types';
import { StyledArrowRighContainer, StyledMoreInfoProductsPdfStore, StyledProductPdfCardInner, StyledProductPdfCardWrapper, StyledProductPdfTextContainer, StyledProductPdfTextInnerContainer, StyledProductPdfThumbnailWrapper, StyledProductsPdfHeadingWrapper, StyledProductsPdfHeadingWrapperAreaOfCare, StyledQrCodeForProduct } from './products-components.style';
import { Heading } from '../../../../../ui-components/heading/heading';
import { Text } from '../../../../../ui-components/text/text';
import { ProductIconTab } from '../../../../product/product-icon-tab/product-icon-tab';
import { ImageComponent } from '../../../../image-component/image-component';
import { AppContext } from '../../../../../app-context-provider/app-context-provider';
import { generateQRCode } from '../generate-qr';
import { ArrowRightIcon } from '../../../../icons-library/arrow-right';
import theme from '../../../../../style/theme';
import { ComponentPiece } from '../../pdf-renderer/pdf-renderer';
import { hasBlock } from '../page/page-components';


const productsHeader: ComponentPiece = {
    elements: (data) => {
        const { localize } = useContext(AppContext);

        if (!hasBlock(['atos/products'], data.blocks)) {
            return [];
        }        

        let categoriesString = '';

        productsStore?.selectedProductCategories?.forEach((category: NestedCategory, index: number) => {
            const isLast = productsStore?.selectedProductCategories?.length - 1 === index;
            const suffix = isLast ? '' : ', ';
        
            categoriesString += `${category._data.name}${suffix}`;
        });

        let typesString = '';

        productsStore?.selectedTypes?.forEach((type: NestedCategory, index: number) => {
            const isLast = productsStore?.selectedTypes?.length - 1 === index;
            const suffix = isLast ? '' : ', ';
        
            typesString += `${type._data.name}${suffix}`;
        });

        const header = (
            <div>
                <Heading tag="h1" variant={'giantRedPDF'}>
                    {localize('products', 'Products')}
                </Heading>

                <StyledProductsPdfHeadingWrapper>
                    <StyledProductsPdfHeadingWrapperAreaOfCare>
                        <Heading tag="h2" variant={'tinyGrayPDF'}>
                            {productsStore?.labels?.topCategoryHeading}
                        </Heading>
                        <Text fontSize="normalPDF" lineLimit={6}>
                            {productsStore?.areaOfCare?.name}
                        </Text>
                    </StyledProductsPdfHeadingWrapperAreaOfCare>

                    <Show when={productsStore?.selectedProductCategories?.length}>
                        <div>
                            <Heading tag="h2" variant={'tinyGrayPDF'}>
                                {productsStore?.labels?.firstNestedCategoryHeading}
                            </Heading>
                            <Text fontSize="normalPDF" lineLimit={6}>
                                {categoriesString}
                            </Text>
                        </div>
                    </Show>

                    <Show when={productsStore?.selectedTypes?.length}>
                        <StyledProductsPdfHeadingWrapperAreaOfCare>
                            <Heading tag="h2" variant={'tinyGrayPDF'}>
                                {productsStore?.labels?.secondNestedCategoryHeading}
                            </Heading>
                            <Text fontSize="normalPDF" lineLimit={6}>
                                {typesString}
                            </Text>
                        </StyledProductsPdfHeadingWrapperAreaOfCare>
                    </Show>
                </StyledProductsPdfHeadingWrapper>
            </div>
        );

        return [
            header,
        ];
    }
};

const productCards: ComponentPiece = {
    canBeBrokenUp: true,
    elements: (data) => {
        const { siteInfo, localize } = useContext(AppContext);

        if (!hasBlock(['atos/products'], data.blocks)) {
            return [];
        }

        if (productsStore?.results?.length <= 0) {
            return [];
        }

        const cards = productsStore.results.map((product: ProductListCardData) => {
            const hasFeaturedImage = product?.images?.find((image: { isFeatured: boolean }) => image.isFeatured);
            let featuredImage = product?.images?.[ 0 ]?.file?.url;

            if (hasFeaturedImage) {
                featuredImage = hasFeaturedImage.file?.url;
            }

            return (
                <StyledProductPdfCardWrapper>
                    <StyledProductPdfCardInner>
                        <StyledProductPdfThumbnailWrapper>
                            <ProductIconTab iconName={product?.icon} width="4.6rem" top="1.2rem" size={2.5} height={'3.5rem'}/>
                            <Show when={featuredImage} fallback={<div></div>}>
                                <ImageComponent
                                    src={featuredImage!}
                                    height={'AUTO'}
                                    width={500}
                                    altText={''}
                                    title={''}
                                    imageText={''}
                                    aspectRatio={'original'} // TODO: this has been changed on dev branch (to wide?)
                                    roundCorners={false}
                                    spacer={false}
                                    ratio={product?.images?.length && product?.images[ 0 ].file.width / product?.images[ 0 ].file.height}
                                    forPdfBuilder={true}
                                />
                            </Show>
                        </StyledProductPdfThumbnailWrapper>
                        <StyledProductPdfTextContainer>
                            <StyledProductPdfTextInnerContainer>
                                <Heading tag="h5" variant="mediumPDF">
                                    {product?.post_title}
                                </Heading>
                                <Text fontSize="normalPDF" lineLimit={4}>
                                    {product?.shortDescription}
                                </Text>
                            </StyledProductPdfTextInnerContainer>
                            <StyledMoreInfoProductsPdfStore>
                                <Heading tag="h5" variant="tinyRedPDF" noBlockSpacing={true}>
                                    {localize('learn-more', 'Learn more')}
                                </Heading>
                                <StyledArrowRighContainer>
                                    <ArrowRightIcon fill={theme.palette.red} width={1.2} />
                                </StyledArrowRighContainer>
                            </StyledMoreInfoProductsPdfStore>
                        </StyledProductPdfTextContainer>
                        <StyledQrCodeForProduct
                            hasProductIcon={!!product?.icon}
                            innerHTML={generateQRCode( 
                                `${siteInfo?.protocol}://${siteInfo?.url}${product?.url}`
                            )} />
                    </StyledProductPdfCardInner>
                </StyledProductPdfCardWrapper>
            );
        });

        return [
            ...cards,
        ];
    },
};


export const components = [
    productsHeader,
    productCards,
];