import { gql } from 'graphql-request';
import { createMemo, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ProductListCardData, ProductListDataContainer } from '../product/product-types';
import { mapProductMetaToListCardData } from './map-product-meta-to-list-card-data';

const GET_PRODUCTS_QUERY = gql`
    query GetProducts(
        $tags: [String],
        $limit: Int
    ) {
        products: resources(
            type: ["product"]
            tags: $tags
            limit: $limit
            order: DESC
        ) {
            key
            meta {
                postTitle
                categories
                description
                featuredImage
                extra
            }
        }
    }
`;

const GET_PRODUCT_CATEGORIES = gql`
    query GetProductCategories(
        $key: String
    ) {
        resource(
            key: $key
        ) {
            key
            content
        }
    }
`;

export const fetchProductCategories = () => {
    const { createCachedResource } = useContext(AppContext);

    const [productCategories] = createCachedResource(
        GET_PRODUCT_CATEGORIES,
        {
            key: '/wp-json/rawb/v1/product-categories',
        },
        true
    );

    return createMemo(() => {
        const categories = productCategories()?.resource?.content?.productCategories;
        if (!categories) return [];
        return categories;
    });
};


type ProductsFetchprops = {
    tag?: {
        slug: string;
    };
    limit?: number;
};

export const fetchProducts = (props: ProductsFetchprops) => {
    const { createCachedResource, productsEntityName } = useContext(AppContext);
    const [productsContent] = createCachedResource(
        GET_PRODUCTS_QUERY,
        () => ({
            ...(props?.tag?.slug ? { tags: [props?.tag?.slug] } : null),
            ...(props?.limit ? { limit: props?.limit } : null),
        }),
        true
    );

    return createMemo((): ProductListCardData[] =>
        productsContent()?.products
            ?.map((product: ProductListDataContainer) => {
                return mapProductMetaToListCardData(product, productsEntityName);
            })
            // We sort products them oldest first because editors have used the date to prioritize them artificially
            .sort((a: ProductListCardData, b: ProductListCardData) => {
                // Ordered based on the post's order ranking and date
                const dateA = a.date ? new Date(a.date).getTime() : 0; // Use 0 as default if a.date is undefined
                const dateB = b.date ? new Date(b.date).getTime() : 0; // Use 0 as default if b.date is undefined
                const orderRankA = (a.productOrderRanking && parseInt(a.productOrderRanking, 10)) || 10;
                const orderRankB = (b.productOrderRanking && parseInt(b.productOrderRanking, 10)) || 10;

                if (orderRankA !== orderRankB) {
                    return orderRankA - orderRankB;
                }

                return dateA - dateB;
            })
    );
};
