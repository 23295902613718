export type FormData = {
    id: number;
    post_title: string;
    permalink: string;
    guid: string;
    externalId: string;
    blocks: any[]
}

export type FormStoreKey = keyof CareRegistrationFormStore | keyof RegistrationFormStore;

export type FormField = {
    name: string;
    required: boolean;
    slug: string;
    type?: string;
    autoWidth?: boolean;
    autocomplete?: string;
};

export type CareRegistrationFormStore = {
    email: string;
    firstname: string;
    lastname: string;
    street: string;
    zip: string;
    city: string;
    'is-caregiver': boolean;
    'patient-name'?: string;
    'patient-street'?: string;
    'patient-postcode'?: string;
    'patient-city'?: string;
    'patient-email'?: string;
    password: string;
    verifyPassword: string;
    consent: boolean;
    site: string;
    company?: string;
};

export type RegistrationFormStore = {
    email: string;
    firstname: string;
    lastname: string;
    country: string;
    city: string;
    'user-type': string;
    institution: string;
    password: string;
    verifyPassword: string;
    consent: boolean;
    referer: string;
    company?: string;
};

export type UserTypes = 'HCP' | 'Other';

export const validUserTypes: UserTypes[] = ['HCP', 'Other'];

