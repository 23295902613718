import { createSignal, For, Match, onCleanup, onMount, Switch, useContext } from 'solid-js';
import { Heading } from '../../ui-components/heading/heading';
import { Modal } from '../../ui-components/modal/modal';
import { 
    StyledDotButton, 
    StyledDotsContainer,
    StyledModalInner,
    StyledVideosContainerTablet,
} from './video-modal.styles';
import { Link } from '../../ui-components/link/link';
import { VideoData } from '../videos/videos-types';
import { AngleLeftIcon } from '../icons-library/angle-left';
import { AngleRightIcon } from '../icons-library/angle-right';
import theme from '../../style/theme';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { ImageWordpress } from '../../types/shared';
import { VideoIframeContainer } from '../../ui-components/video-iframe-container/video-iframe-container';

type ModalVideoData ={
    selectedPostSlug?: string;
    text?: string;
    heading?: string;
    image?: ImageWordpress;
    video?: VideoData;
}

type VideoModalProps = {
    show: boolean;
    closeModal: () => void;
    video?: {
        videoLink?: string;
    };
    modalVideoTitle?: string;
    modalLink?: {
        title: string;
        url: string;
    };
    videos?: ModalVideoData[];
    initialVideoIndex?: number;
    closeButtonRef?: any;
};

export const VideoModal = (props: VideoModalProps) => {
    const { viewport } = useContext(AppContext);

    const [ currentVideoIndex, setCurrentVideoIndex ] = createSignal(props.initialVideoIndex || 0);

    const isUnderTablet = () => viewport.width <= theme.breakpoints.ORIGINAL_TABLET;

    let keydownListener: any;
    onMount(() => {
        keydownListener = window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                props.closeModal();
            }
        });
        
        onCleanup(() => {
            window.removeEventListener('keydown', keydownListener);
        });
    });

    
    const previousVideo = () => () => {
        if (currentVideoIndex() > 0) {
            setCurrentVideoIndex(currentVideoIndex() - 1);
            return;
        }
        setCurrentVideoIndex(props.videos!.length - 1);
    };

    const nextVideo = () => () => {
        if (currentVideoIndex() < (props.videos!.length - 1)) {
            setCurrentVideoIndex(currentVideoIndex() + 1);
            return;
        }
        setCurrentVideoIndex(0);
    };

    const currentVideo = () => {
        if (props.videos) {
            return props.videos[currentVideoIndex()];
        }
    };

    const videoComponentForMultipleVideos = () => {
        return (
            <div style={{ height: 'auto', width: '100%' }}>
                <VideoIframeContainer videoLink={currentVideo()?.video?.video_link} />
            </div>
        );
    };

    return (
        <Modal 
            visible={props.show}
            onClose={props.closeModal}
            size='large'
            darkenBackground={true}
            closeButtonRef={(el: HTMLElement) => props.closeButtonRef && props.closeButtonRef(el)}
        >
            <StyledModalInner multipleVideos={Boolean(props.videos?.length)}>
                <Switch>

                    <Match when={props.video}>
                        <Heading tag="h5" variant='medium' noBlockSpacing={true}>
                            {props.modalVideoTitle}
                        </Heading>

                        <div style={{ height: 'auto', width: '100%' }}>
                            <VideoIframeContainer videoLink={props?.video?.videoLink} />
                        </div>

                        <Link
                            label={props.modalLink?.title || ''}
                            url={props.modalLink?.url || ''}
                            noBlockSpacing={true}
                        />
                    </Match>

                    <Match when={props.videos}>
                        <Heading tag="h5" variant='medium' noBlockSpacing={true}>
                            {props!.videos![currentVideoIndex()].heading}
                        </Heading>

                        <Switch>
                            <Match when={!isUnderTablet()}>
                                <StyledVideosContainerTablet>
                                    <button onClick={previousVideo()}>
                                        <AngleLeftIcon fill={theme.palette.gray} />
                                    </button>

                                    {videoComponentForMultipleVideos()}

                                    <button onClick={nextVideo()}>
                                        <AngleRightIcon fill={theme.palette.gray} />
                                    </button>
                                </StyledVideosContainerTablet>

                                <StyledDotsContainer>
                                    <For each={props.videos}>{(video: ModalVideoData, index) => (
                                        <StyledDotButton
                                            active={currentVideoIndex() === index()}
                                            onClick={() => setCurrentVideoIndex(index)}
                                        />
                                    )}</For>
                                </StyledDotsContainer>
                            </Match>

                            <Match when={isUnderTablet()}>
                                {videoComponentForMultipleVideos()}

                                <StyledFlexRow gap="2rem">
                                    <button onClick={previousVideo()}>
                                        <AngleLeftIcon fill={theme.palette.gray} />
                                    </button>

                                    <StyledDotsContainer>
                                        <For each={props.videos}>{(video: ModalVideoData, index) => (
                                            <StyledDotButton
                                                active={currentVideoIndex() === index()}
                                                onClick={() => setCurrentVideoIndex(index)}
                                            />
                                        )}</For>
                                    </StyledDotsContainer>

                                    <button onClick={nextVideo()}>
                                        <AngleRightIcon fill={theme.palette.gray} />
                                    </button>
                                </StyledFlexRow>
                            </Match>
                        </Switch>
                    </Match>
                </Switch>
            </StyledModalInner>
        </Modal>
    );
};

