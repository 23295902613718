import { gql } from 'graphql-request';
import { createMemo, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { EventData, EventListCardData, EventListDataContainer } from '../../event/event-types';
import { mapEventMetaToListCardData } from './map-event-meta-to-list-card-data';

const GET_EVENTS_QUERY = gql`
    query GetEvents(
        $siteId: String,
        $type: [String],
        $tags: [String],
        $limit: Int,
        $fromDate: String,
        $toDate: String
    ) {
        events: resources(
            siteId: $siteId,
            type: $type,
            tags: $tags,
            limit: $limit,
            order: DESC,
            fromDate: $fromDate,
            toDate: $toDate
        ) {
            key
            meta {
                postTitle
                categories
                description
                featuredImage
                extra
            }
        }
    }
`;

export const fetchEvents = (props: EventsProps) => {
    const { createCachedResource, eventsEntityName } = useContext(AppContext);

    const vars = {
        type: ['event'],
        ...props?.tag?.slug
            ? { tags: [props?.tag?.slug] }
            : null,
        ...props?.limit
            ? { limit: props?.limit }
            : null,
        ...props?.fromDate
            ? { fromDate: props.fromDate }
            : null,
        ...props?.toDate
            ? { toDate: props.toDate }
            : null,
    };

    const [eventsContent] = createCachedResource(GET_EVENTS_QUERY, vars, true);

    return createMemo((): EventListCardData[] =>
        eventsContent()?.events
            ?.map((event: EventListDataContainer) => {
                return mapEventMetaToListCardData(event, eventsEntityName);
            })
            .sort((a: EventListCardData, b: EventListCardData) => {
                const dateA = a.date ? new Date(a.date).getTime() : 0; // Use 0 as default if a.date is undefined
                const dateB = b.date ? new Date(b.date).getTime() : 0; // Use 0 as default if b.date is undefined
                return dateA - dateB;
            })
    );
};

export type EventContainer = {
    content: EventData;
};

type EventsProps = {
    tag?: {
        slug: string;
        name?: string;
    };
    limit?: number;
    fromDate?: string;
    toDate?: string;
};
