import { styled } from 'solid-styled-components';
import theme from '../../../style/theme';
import { arrowLinkStyle } from '../../../ui-components/link/link.styles';
import { StyledLinkProps } from '../../../ui-components/link/link-types';
import { Link } from '@solidjs/router';

export const StyledSecondaryMenu = styled.ul`
    display: flex;
    flex-direction: column;

    & > *:last-child {
        margin-bottom: 4rem;
    }

    li {
        padding-bottom: unset;
    }

    a {
        font-size: 1.4rem;
        padding-block: 1rem;
        display: block;

        span {
            padding-left: unset;
        }
    }

    ${theme.mediaQueries.desktopMax} {
        a {
            font-size: 1.3rem;
        }
    }

    ${theme.mediaQueries.tabletMaxOriginal} {
        a {
            font-size: 1.15rem;
        }
    }
`;

export const StyledSecondLevelItems = styled.ul`
    border-top: 1px solid ${theme.palette.red};
`;

export const StyledSecondLevelItem = styled.li`
    border-bottom: 1px solid ${theme.palette.red};
`;

export const StyledThirdLevelItems = styled.ul`
    border-top: 1px solid ${theme.palette.red};

    li + li {
        border-top: 1px solid ${theme.palette.red};
    }
`;

export const StyledThirdLevelItem = styled.li``;

export const StyledLinkContainer = styled(Link)<StyledLinkProps & { paddingLeft?: string }>`
    display: flex;
    align-items: flex-end;
    position: relative;
    padding-left: ${p => p.paddingLeft || '0'};

   ${p => arrowLinkStyle(p)}
   ${p => p.active ? `color: ${theme.palette.red};` : ''}
`;

export const StyledChevronContainer = styled.div<{ isSecondLevel?: boolean }>`
    position: absolute;
    top: 18px;
    left: ${p => p.isSecondLevel ? '10px' : '-1px'};

    ${theme.mediaQueries.desktopMax} {
        top: 16px;
    }

    ${theme.mediaQueries.tabletMaxOriginal} {
        top: 14px;
    }
`;
