import { BlogNewsListCardData, BlogNewsListDataContainer } from '../blog-news/blog-news';

export const mapBlogMetaToListCardData = (post: BlogNewsListDataContainer, blogAndNewsEntityName: string): BlogNewsListCardData => {
    // We map the new meta data to the pre-existing data structure for backwards compatibility

    const slug = post.key.replace(/\/blog-and-news\//, '').replace(/\//g, '');

    const mappedData: BlogNewsListCardData = {
        url: `/${blogAndNewsEntityName}/${slug}`,
        permalink: post.key,
        categories: post?.meta?.categories,
        ...post?.meta?.extra,
    };

    return mappedData;
};


