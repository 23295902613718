/*
 * This file is where we map Gutenberg blocks to SolidJs components
 */

import { Component } from 'solid-js';

/* UI Components */
import { Button } from '../ui-components/button/button';
import { Heading } from '../ui-components/heading/heading';
import { Quote } from '../ui-components/quote/quote';
import { Text } from '../ui-components/text/text';

/* Components */
import { ImageComponent as Image } from '../components/image-component/image-component';

/* Grid System */
import { BlogsNews } from '../components/blogs-news/blogs-news';
import { BusinessCard } from '../components/business-card/business-card';
import { BusinessCardProps } from '../components/business-card/business-card.d';
import { CardSlider } from '../components/card-slider/card-slider';
import { ContentHub } from '../components/content-hub/content-hub';
import { ContentHubProps } from '../components/content-hub/content-hub-types';
import { Events } from '../components/events/events';
import { FormSelector } from '../components/form-selector/form-selector';
// import { Forms } from '../components/forms/forms';
// import { FormProps } from '../components/forms/forms.d';
import { Hero } from '../components/hero/hero';
import { HeroCampaign } from '../components/hero-campaign/hero-campaign';
import { HeroComponentProps } from '../components/hero/hero-types';
// import { InstructionsForUse } from '../components/instructions-for-use/instructions-for-use';
import { MyContent } from '../components/my-content/my-content';

import { PasswordReset } from '../components/password-reset/password-reset';
import { ProductsCollection } from '../components/products-collection/products-collection';
import { ProductsHero } from '../components/products-hero/products-hero';
import { ProductsSpotlight } from '../components/products-spotlight/products-spotlight';
import { Products } from '../components/products/products';
import { RegistrationFormEntry } from '../components/registration-form/entry';
import { RequestPasswordReset } from '../components/request-password-reset/request-password-reset';
import { SearchWidget } from '../components/search-widget/search-widget';
import { Search } from '../components/search/search';
import { Feed } from '../components/social-media-feed/feed';
import { VerifyAccount } from '../components/verify-account/verify-account';
import { VideoEmbed } from '../components/video-embed/video-embed';
import { VideoEmbedComponentProps } from '../components/video-embed/video-embed-types';
import { Videos } from '../components/videos/videos';
import { Column } from '../grid-system/column/column';
import { Section } from '../grid-system/section/section';
import { ButtonProps } from '../ui-components/button/button-types';
import { Divider } from '../ui-components/divider/divider';
import { DividerComponentProps } from '../ui-components/divider/divider-types';
import { HeadingProps } from '../ui-components/heading/heading.d';
import { Link } from '../ui-components/link/link';
import { CustomLinkProps } from '../ui-components/link/link-types';
import { List } from '../ui-components/list/list';
import { ListItem } from '../ui-components/list/list-item';
import { Testimony } from '../ui-components/testimony/testimony';
import { TextProps } from '../ui-components/text/text.d';
import { PdfBuilderDashboard } from '../components/pdf-builder/pdf-dashboard/pdf-dashboard';
import { VideoPdf } from '../components/pdf-builder/pdf-factory/components/blocks/video/video-pdf';
import { PodcastPdf } from '../components/pdf-builder/pdf-factory/components/blocks/podcast/podcast-pdf';
import { SpacerPdf } from '../components/pdf-builder/pdf-factory/components/blocks/spacer/spacer-pdf';
import { HeroPdf } from '../components/pdf-builder/pdf-factory/components/blocks/hero/hero-pdf';
import { CardSliderPdf } from '../components/pdf-builder/pdf-factory/components/blocks/slider/slider-pdf';
import { TestimonyPdf } from '../components/pdf-builder/pdf-factory/components/blocks/testimony/testimony-pdf';
import { BackgroundImagePDF } from '../components/pdf-builder/pdf-factory/components/blocks/bg-image/bg-image-pdf';
import { Files } from '../components/files/files';
import { File } from '../components/file/file';
import { Basket } from '../components/basket/basket';
import { ProfileImages } from '../components/profile-images/profile-images';
import { FaqSelector } from '../components/faq-selector/faq-selector';
import { Podcast } from '../components/podcast/podcast';


export type ComponentMap = {
    'atos/heading': Component<HeadingProps>;
    'atos/paragraph': Component<TextProps>;
    'atos/button': Component<ButtonProps>;
    'core/navigation-link': Component<CustomLinkProps>;
    'atos/hero': Component<HeroComponentProps>;
    'atos/hero-campaign': Component<HeroComponentProps>;
    'next24hr/section': any;
    'next24hr/column': any;
    'atos/image': any;
    'atos/products': any;
    'atos/quote': any;
    'atos/testimony': any;
    'atos/testimony-pdf': any;
    'atos/products-collection': any;
    'atos/events': any;
    'atos/blogs-news': any;
    'atos/products-spotlight': any;
    'atos/business-card': Component<BusinessCardProps>;
    'atos/products-hero': any;
    'atos/video-embed': Component<VideoEmbedComponentProps>;
    // 'atos/forms': Component<FormProps>;
    'atos/videos': any;
    'atos/form-selector': any;
    'atos/slider': any;
    // 'atos/manuals': any;
    'core/list': any;
    'core/list-item': any;
    'atos/divider': Component<DividerComponentProps>;
    'atos/social-media-feed': Component<any>;
    'atos/content-hub': Component<ContentHubProps>;
    'atos/registration-form': any;
    'atos/search': Component<any>;
    'atos/search-widget': Component<any>;
    'atos/request-password-reset': any;
    'atos/reset-password': any;
    'atos/verify-account': any;
    'atos/my-content': any;
    'atos/pdf-builder': any;
    'atos/video-embed-pdf': any;
    'atos/spacer-pdf': any;
    'atos/hero-pdf': any;
    'atos/slider-pdf': any;
    'atos/bg-image-pdf': any;
    'atos/podcast-pdf': any;
    'atos/files': any;
    'atos/file': any;
    'atos-care/basket': any;
    'atos/profile-images': any;
    'atos/faq-selector': any;
    'atos/podcast': any;
};

export const componentMap: ComponentMap = {
    'atos/heading': Heading,
    'atos/paragraph': Text,
    'atos/button': Button,
    'core/navigation-link': Link,
    'next24hr/section': Section,
    'next24hr/column': Column,
    'atos/image': Image,
    'atos/hero': Hero,
    'atos/hero-campaign': HeroCampaign,
    'atos/products': Products,
    'atos/quote': Quote,
    'atos/testimony': Testimony,
    'atos/testimony-pdf': TestimonyPdf,
    'atos/products-collection': ProductsCollection,
    'atos/events': Events,
    'atos/blogs-news': BlogsNews,
    'atos/products-spotlight': ProductsSpotlight,
    'atos/business-card': BusinessCard,
    'atos/products-hero': ProductsHero,
    'atos/video-embed': VideoEmbed,
    // 'atos/forms': Forms,
    'atos/videos': Videos,
    'atos/form-selector': FormSelector,
    'atos/slider': CardSlider,
    // 'atos/manuals': InstructionsForUse,
    'core/list': List,
    'core/list-item': ListItem,
    'atos/divider': Divider,
    'atos/social-media-feed': Feed,
    'atos/content-hub': ContentHub,
    'atos/registration-form': RegistrationFormEntry,
    'atos/search': Search,
    'atos/search-widget': SearchWidget,
    'atos/request-password-reset': RequestPasswordReset,
    'atos/reset-password': PasswordReset,
    'atos/verify-account': VerifyAccount,
    'atos/my-content': MyContent,
    'atos/pdf-builder': PdfBuilderDashboard,
    'atos/video-embed-pdf': VideoPdf,
    'atos/spacer-pdf': SpacerPdf,
    'atos/hero-pdf': HeroPdf,
    'atos/slider-pdf': CardSliderPdf,
    'atos/bg-image-pdf': BackgroundImagePDF,
    'atos/podcast-pdf': PodcastPdf,
    'atos/files': Files,
    'atos/file': File,
    'atos-care/basket': Basket,
    'atos/profile-images': ProfileImages,
    'atos/faq-selector': FaqSelector,
    'atos/podcast': Podcast,
};
