import { useLocation } from '@solidjs/router';
import { For, Match, Show, Switch, useContext } from 'solid-js';
import theme from '../../../style/theme';
import { ErrorCatcher } from '../../../tools/error-catcher';
import urlMatches from '../../../tools/url-match';
import { Link } from '../../../ui-components/link/link';
import { PageContext } from '../../page/page-context-provider';
import { StyledChevronContainer, StyledLinkContainer, StyledSecondLevelItem, StyledSecondLevelItems, StyledSecondaryMenu, StyledThirdLevelItem, StyledThirdLevelItems } from './sidebar-menu-desktop.style';
import { ChevronUpBoldIcon } from '../../icons-library/chevron-up-bold';
import { isExternalLink } from '../../../tools/check-is-external-url';
import { isDownloadLink } from '../../../tools/check-is-download-link';
import { removeTrailingSlash } from '../../../tools/remove-trailing-slash';
import removeAmpersand from '../../../tools/remove-ampersand';

export type MenuContent = {
    menu: MenuItem[];
    menuName: string;
};

export type MenuItem = {
    url: string;
    title: string;
    children?: MenuItem[];
};

export const SidebarMenuDesktop = () => {
    const { sidebarMenu } = useContext(PageContext);

    const location = useLocation();

    const opensInNewTab = (url: string) => isExternalLink(url) || isDownloadLink(url);

    const openMenuItems = (item: MenuItem) => {
        let childItemMatches = false;
        if (!item.children) {
            return false;
        } else {

            // We go deeper
            item.children.map((childItem: MenuItem) => {
                if (openMenuItems(childItem)) {
                    childItemMatches = true;
                }
            });
        }

        if (childItemMatches) {
            return true;
        }

        if (urlMatches(item.url)) {
            return true;
        }

        return item.children.some((child) => urlMatches(child.url));
    };

    const formatUrl = (url: string) => {
        let newUrl = removeTrailingSlash(url);
        newUrl = removeAmpersand(newUrl);
        return newUrl;
    };

    return (
        <ErrorCatcher componentName="Secondary menu">
            <StyledSecondaryMenu>
                <For each={sidebarMenu()?.menu}>
                    {(item: MenuItem) => (
                        <li>
                            <StyledLinkContainer
                                href={formatUrl(item.url)}
                                active={urlMatches(item.url)}
                                bold
                                labelColor={theme.palette.darkGray}
                                paddingLeft='1.2rem'
                                {...(opensInNewTab(item.url)
                                    ? {
                                        target: '_blank',
                                        rel: 'noopener noreferrer',
                                    }
                                    : {})} 
                            >
                                <Show when={item.children}>
                                    <StyledChevronContainer>
                                        <ChevronUpBoldIcon
                                            width={1}
                                            height={1}
                                            bold={false}
                                            rotate={openMenuItems(item) ? 'down' : 'right'}
                                        />
                                    </StyledChevronContainer>
                                </Show>
                                <span>{removeAmpersand(item.title)}</span>
                            </StyledLinkContainer>
                            <Show when={openMenuItems(item)}>
                                <StyledSecondLevelItems>
                                    <For each={item.children}>
                                        {(childLink) => (
                                            <StyledSecondLevelItem>
                                                <StyledLinkContainer
                                                    href={childLink?.url?.includes('#') 
                                                        ? formatUrl(location.pathname + (childLink?.url || '#'))
                                                        : formatUrl(childLink?.url)
                                                    }
                                                    active={urlMatches(childLink.url)}
                                                    bold={false}
                                                    labelColor={theme.palette.gray}
                                                    paddingLeft={'2.2rem'}
                                                    {...(opensInNewTab(childLink.url)
                                                        ? {
                                                            target: '_blank',
                                                            rel: 'noopener noreferrer',
                                                        }
                                                        : {})} 
                                                >
                                                    <Show when={childLink.children}>
                                                        <StyledChevronContainer isSecondLevel>
                                                            <ChevronUpBoldIcon
                                                                width={1}
                                                                height={1} 
                                                                bold={false} 
                                                                rotate={openMenuItems(childLink) ? 'down' : 'right'}
                                                            />
                                                        </StyledChevronContainer>
                                                    </Show>
                                                    <span>{removeAmpersand(childLink.title)}</span>
                                                </StyledLinkContainer>

                                                <Show when={openMenuItems(childLink)}>
                                                    <StyledThirdLevelItems>
                                                        <For each={childLink.children}>
                                                            {(secondChildLink) => (
                                                                <StyledThirdLevelItem>
                                                                    <div style={{ 'padding-left': '3.2rem' }}>
                                                                        <Switch>
                                                                            <Match when={secondChildLink?.url?.includes('#')}>
                                                                                <Link
                                                                                    label={secondChildLink?.title}
                                                                                    anchorLink={secondChildLink?.url}
                                                                                    url={location.pathname + (secondChildLink?.url || '#')}
                                                                                    plainStyle={true}
                                                                                    labelColor={theme.palette.gray}
                                                                                    bold={false}
                                                                                    breakWords={true}
                                                                                />
                                                                            </Match>
                                                                            <Match when={!secondChildLink?.url?.includes('#')}>
                                                                                <Link
                                                                                    plainStyle={true}
                                                                                    bold={false}
                                                                                    labelColor={theme.palette.gray}
                                                                                    url={secondChildLink?.url}
                                                                                    label={secondChildLink?.title}
                                                                                    breakWords={true}
                                                                                />
                                                                            </Match>
                                                                        </Switch>

                                                                    </div>
                                                                </StyledThirdLevelItem>
                                                            )}
                                                        </For>
                                                    </StyledThirdLevelItems>
                                                </Show>

                                            </StyledSecondLevelItem>
                                        )}
                                    </For>
                                </StyledSecondLevelItems>
                            </Show>
                        </li>
                    )}
                </For>
            </StyledSecondaryMenu>
        </ErrorCatcher>
    );
};
