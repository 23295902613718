/* eslint-disable indent */
import { styled } from 'solid-styled-components';
import theme, { palette, fontFamilyAtosBold, fontFamilyAtosRegular, fontFamilyAtosLight } from '../../style/theme';
import { StyledParagraphProps } from './text.d';
import { limitLines } from '../../tools/limit-lines';



export const defaultTextStyle = `
    font-weight: 400;
    font-family: ${fontFamilyAtosRegular};
    margin-bottom: 1.2em;
    margin-top: 0;
    font-size: 2rem;
    letter-spacing: 0;
    color: ${theme.colors.text.paragraph};
    &:last-child {
        margin-bottom: 0;
    }
`;

/* Sizes */
export const largest = `
    font-size: 2rem;
    line-height: 3rem;
`;

export const larger = `
    font-size: 1.8rem;
    line-height: 2.8rem;
`;

export const large = `
    font-size: 1.8rem;
    line-height: 3.2rem;
`;

export const normal = `
    font-size: 1.6rem;
    line-height: 2.6rem;
`;

export const small = `
    font-size: 1.3rem;
    line-height: 2.1rem;
`;

export const smaller = `
    font-size: 0.9rem;
    line-height: 1.2rem;
`;

export const footerSmall = `
    font-size: 1.2rem;
    line-height: 130%;
`;

export const footerHeading = `
    font-size: 1.3rem;
    line-height: 2.1rem;
    margin-bottom: 0.9em;
`;

// PDF Builder styles:
export const largestPDF = `
    font-size: 1.48rem;
    font-style: normal;
    line-height: normal;
`;

export const largerPDF = `
    font-size: 1.33rem;
`;

export const largePDF = largerPDF;

export const normalPDF = `
    font-family: ${fontFamilyAtosLight};
    font-size: 1.09rem;
`;

export const smallPDF = `
    font-family: ${fontFamilyAtosLight};
    font-size: 1.04rem;
    line-height: 140%;
`;

export const smallerPDF = `
    font-family: ${fontFamilyAtosLight};
    font-size: 0.95rem;
    line-height: normal;
`;

export const tinyPDF = `
    font-family: ${fontFamilyAtosLight};
    font-size: 0.75rem;
    line-height: 140%;
`;

export const textSizes = {
    largest,
    larger,
    large,
    normal,
    small,
    smaller,
    footerSmall,
    footerHeading,
    // PDF Builder styles:
    largestPDF,
    largerPDF,
    largePDF,
    normalPDF,
    smallPDF,
    smallerPDF,
    tinyPDF,
};

type RedLineProps = {
    withRedLine: boolean | undefined;
    isList?: boolean;
}

export const redLineStyle = (p: RedLineProps) => `
    padding-left: ${p.withRedLine 
        ? `${p.isList ?  (2 * 2) - (0.7) : 2}rem` 
        : `${p.isList ?  2 - 0.7 : '0'}rem`
    };
    border-left: ${p.withRedLine ? `${theme.redLineThickness} solid ${palette.red}` : 'none'};
    ${theme.mediaQueries.tabletMax} {
        padding-left: ${p.withRedLine 
            ? `${p.isList ?  (1.5 + 1.3) : 1.5}rem` 
            : `${p.isList ?  1.3 : 0}rem`
        };
    }

    ${theme.mediaQueries.mobileMax} {
        padding-left: ${p.withRedLine 
            ? `${p.isList ?  1 + 1.3 : 1}rem` 
            : `${p.isList ?  1.3 : 0}rem`
        };
    }
`;

export const StyledParagraph = styled.p<StyledParagraphProps>`
    ${defaultTextStyle};
    ${(p) => textSizes[p.fontSize]};
    ${(p) => redLineStyle({ withRedLine: p.displayRedVerticalLine })}
    color: ${p => {
        if (p.fontStyle === 'bold' && !p.color) {
            return theme.palette.darkGray;
        }

        if (p.color === 'defer') {
            return 'inherit';
        }

        const themeColor = theme.palette[p.color];
        if (themeColor) {
            return themeColor;
        }
        
        return p.color;
    }};
    ${(p) => (p.lineLimit ? limitLines(p.lineLimit) : '')}
    ${(p) => (p.align ? `text-align: ${p.align};` : '')};
    ${(p) => (p.underlined ? 'text-decoration-line: underline' : '')};
    ${(p) => (p.fontStyle === 'italic' ? 'font-style: italic;' : '')}
    ${(p) =>
        p.noBlockSpacing
            ? `
            margin-block: 0 !important;
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        `
            : ''}

    ${(p) => p.fontStyle === 'bold' ? `
        font-family: ${fontFamilyAtosBold};
        font-weight: 800;
    ` : ''}

    & > a {
        color: ${palette.red};
        text-decoration: underline;
    }
    strong {
        font-family: ${fontFamilyAtosBold};
        font-weight: 800;
    }

    ${p => p.pdfBuilderText ? `
        position: relative;
        padding-bottom: 1em;
        margin-bottom: 0;
    ` : ''}

    ${p => p.isLastParagraphPdf ? `
        padding-bottom: 0;
        margin-bottom: 1.6em;
    ` : ''}

`;
