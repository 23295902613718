import { ProductListCardData, ProductListDataContainer } from '../product/product-types';

export const mapProductMetaToListCardData = (product: ProductListDataContainer, productsEntityName: string): ProductListCardData => {
    // We map the new meta data to the pre-existing data structure for backwards compatibility

    const slug = product.key.replace(/\/product\//, '').replace(/\//g, '');

    const mappedData: ProductListCardData = {
        url: `/${productsEntityName}/${slug}`,
        permalink: product.key,
        'post_title': product.meta?.postTitle,
        categories: product?.meta?.categories,
        shortDescription: product?.meta?.description,
        images: product?.meta?.featuredImage
            ? [{
                file: product?.meta?.featuredImage,
                isFeatured: true,
            }] : [],
        ...product?.meta?.extra,
    };

    return mappedData;
};


