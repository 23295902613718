import { For, Show, createEffect, createSignal, useContext } from 'solid-js';
import { SeoSettings } from '../seo-settings/seo-settings';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { useNavigate } from '@solidjs/router';
import { Text } from '../../ui-components/text/text';
import {
    StyledBasketContainer,
    StyledOrderTable,
    StyledBasketErrorMessage,
} from './basket.styles';
import { gql } from 'graphql-request';
import { ProductData, Specification } from '../product/product-types';
import { ProductContainer } from '../products/products-types';
import BasketOrderSummary from './basket-order-summary';
import { Section } from '../../grid-system/section/section';
import { Column } from '../../grid-system/column/column';
import { BasketLoading, Header } from './basket-components';
import BasketOrderLine from './basket-orderline';
import { BasketContext, OrderLine } from './basket-context-provider';
import theme from '../../style/theme';

const CREATE_ORDER = gql`
    mutation CreateOrder (
        $site: String!,
        $orderDetails: [users_OrderDetailsInput!]!,
        $status: users_OrderStatus!,
    
    ) {
        createOrder(
            site: $site,
            orderDetails: $orderDetails,
            status: $status,
        ) {
            success
            message
        }
    }
`;

const GET_PRODUCTS_QUERY = gql`
    query GetProducts(
        $keys: [String],
    ) {
        products: resources(
            type: ["product"]
            keys: $keys
        ) {
            content
        }
    }
`;

export type MappedOrderDetails = {
    product: ProductData;
    sku: Specification;
    imageUrl: string;
    imageRatio: number;
    line: OrderLine;
};

export const Basket = () => {
    const { shop, graphQlClient, createCachedResource, localize } = useContext(AppContext);
    const { basket, completeOrder } = useContext(BasketContext);
    const [ errorMessage, setErrorMessage ] = createSignal('');
    const [ isPlacingOrder, setIsPlacingOrder ] = createSignal(false);
    const [ productKeys, setProductKeys ] = createSignal<{ keys: string[]; }>();
    const navigate = useNavigate();

    const [ productsContent, { refetch } ] = createCachedResource(
        GET_PRODUCTS_QUERY,
        () => (productKeys),
        true
    );

    createEffect(() => {        
        const keys = basket.order.orderDetails.map((orderLine) => {
            return `/${orderLine.type}/${orderLine.slug}`;
        });

        setProductKeys({ keys });
    });

    const createOrder = async () => {
        setIsPlacingOrder(true);
        await new Promise((resolve) => setTimeout(resolve, 1000)); 

        if (!basket.order.site || !basket.order.email) {
            console.error('Order missing site or user');
            return false;
        }


        try {
            const vars = {
                ...basket.order,
                orderDetails: basket.order.orderDetails,
                status: 'PENDING',
            };

            const response = await graphQlClient(CREATE_ORDER, vars, true);

            if (!response.createOrder.success) {
                handleErrors(response.createOrder.message);
                return;
            }

            completeOrder();

            setIsPlacingOrder(false);

            if (shop.orderConfirmationPage.url) {
                navigate(shop.orderConfirmationPage.url);
            }
        } catch (error) {
            setIsPlacingOrder(false);
            console.error('Error placing order', error);
            setErrorMessage(localize('error-placing-order', 'There was an error placing the order, please try again later'));
        }
    };

    const handleErrors = (message: string) => {
        console.error('Error placing order', message);

        switch (message) {
            case 'order-mismatch':
                refetch();
                setErrorMessage(`<strong>
                    ${localize('order-mismatch', 'The order has changed since you last viewed it. Please review the order and try again')}
                </strong>`);
                break;
            default:
                setErrorMessage(localize('error-placing-order', 'There was an error placing the order, please try again later'));
                break;
        }
    };

    const mappedOrderDetails = () => {
        const mapped = basket.order.orderDetails.map((line) => {            
            if (!line) return null;

            const product: ProductData = productsContent()?.products
                .find((product: ProductContainer) => product.content.id === line.id)
                ?.content;

            if (!product) return null;

            const sku = product?.specifications
                .find((sku) => sku.refNumber === line.skuRefNumber);

            if (!sku) return null;

            let imageUrl;
            let imageRatio;
            if (product?.images?.length) {
                const featuredImage = product.images?.find((image: { isFeatured: boolean; }) => image.isFeatured);
                const image = featuredImage || product.images[ 0 ];
                imageUrl = image.file.url;
                imageRatio = image.file.width / image.file.height;
            }

            return {
                product,
                sku,
                imageUrl,
                imageRatio,
                line,
            };
        });

        return mapped.filter((orderDetail) => orderDetail) as MappedOrderDetails[];
    };

    return (
        <Show when={!productsContent.loading}>
            <SeoSettings title={'Basket'} seo={{ noIndex: true }} />

            <Section widthType='bgFull'>
                <div>
                    <Header />

                    <Show when={errorMessage()}>
                        <StyledBasketErrorMessage>
                            <Text fontSize='small' color={theme.palette.red} fontStyle='italic'>{
                                errorMessage()
                            }</Text>
                        </StyledBasketErrorMessage>
                    </Show>
                </div>
            </Section>

            <Section widthType='bgFull' templateShorthand={[12]}>
                <Column backgroundValue={'white'} roundCorners>
                    <StyledBasketContainer>

                        <Show when={basket.order.orderDetails.length === 0}>
                            <BasketLoading />
                        </Show>

                        <Show when={basket.order.orderDetails.length}>
                            <StyledOrderTable>
                                <For each={mappedOrderDetails()}>{(orderDetails) => (
                                    <BasketOrderLine orderDetails={orderDetails} />
                                )}</For>
                            </StyledOrderTable>

                            <BasketOrderSummary isPlacingOrder={isPlacingOrder} createOrder={createOrder} />
                        </Show>

                    </StyledBasketContainer>
                </Column>
            </Section>
        </Show>
    );

};
